import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "./styles.css";
import { authentication } from "../../redux/actions/authentication/auth";
import _ from "lodash";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import ImageUploading from 'react-images-uploading';
import axios from "axios";         
import { NotificationManager } from 'react-notifications';
import moment from "moment";  
import ResponsivePagination from 'react-responsive-pagination';  
import {
    useStripe,
    useElements,
    CardElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PlacesAutocomplete, {
    geocodeByAddress
} from 'react-places-autocomplete';
import ImageUploader, { FileObjectType as FileUploaderProps } from "react-image-upload";
import ReCAPTCHA from "react-google-recaptcha";
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size';

const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

console.log("stripePromise", stripePromise);

const calculateTotalPages = (totalResults, itemsPerPage) => {
    return Math.ceil(totalResults / itemsPerPage);
};

const ProfileSettingsHelper = ({ authData, authentication }) => {
    console.log("authData authData authData authData", authData);
    const [width, height] = useWindowSize()
    const stripe = useStripe();
    const elements = useElements();
    const [ state, setState ] = useState({
        screen: "default",
        newProfilePic: [],
        instagram: "",
        username: "",
        email: "",
        isCardReady: false,
        isCardReadyTwo: false,
        twitter: "",
        address: "",
        firstName: "",
        lastName: "",
        userDataRestricted: null,
        oldPassword: "",
        newPassword: "",
        newPasswordReentry: "",
        latestAuth: null,
        currentPage: 0,
        totalPages: 0,
        selectedAddress: null,
        imageFile: null,
        captchaCompletedTwo: false,
        addressTwo: "",
        selectedAddressTwo: null,
        paymentMethods: [],
        cardInfo: {
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: ''
        },
        selectedBookmark: null
    });
    
    const [longPress, setLongPress] = useState(false);
    const [pressTimer, setPressTimer] = useState(null);

    const handleDeletionBookmark = (item) => {
        console.log("handleDeletionBookmark clicked/ran...", item);

        const configuration = {
            uniqueId: authData.uniqueId,
            bookmark: item.id
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/remove/previous/bookmark`, configuration).then((res) => {
            if (res.data.message === "Successfully executed logic!") {
                console.log("res.data correct/success: ", res.data);

                const { user } = res.data;

                setState(prevState => ({ ...prevState, userDataRestricted: user })); // state.userDataRestricted.favoritesList

                NotificationManager.success(`Successfully changed/modified your bookmarked listings!`, 'Successfully modified bookmark.', 3000);
            } else if (res.data.message === "Original password do NOT match - please try your request again.") {
                NotificationManager.warning(res.data.message, 'Original password is NOT valid or matching.', 3000);
            } else {
                console.log("res data error :", res.data);
            }
        })
    };

    // Long press handler
    const handleLongPress = (item) => {
        console.log('Long press activated!', item);
        
        setState(prevState => ({ ...prevState, selectedBookmark: item }));

        NotificationManager.info(`Click to remove this selected bookmark!`, 'Click to delete bookmark.', 3000, () => {
            handleDeletionBookmark(item)
        });

        setLongPress(true);
    };

    // Start the timer on press
    const startPressTimer = (item) => {
        // Clear any existing timer
        clearTimeout(pressTimer);
        // Set a new timer
        const timer = setTimeout(() => handleLongPress(item), 800);  // Adjust time as needed
        setPressTimer(timer);
    };

    // Clear the timer on release
    const clearPressTimer = () => {
        clearTimeout(pressTimer);
        setLongPress(false);
    };

    useEffect(() => {
        // Cleanup the timer on component unmount
        return () => clearTimeout(pressTimer);
    }, [pressTimer]);

    const cardElement = elements.getElement(CardElement);

    const navigate = useNavigate();

    const handleFileInputChange = (imageFile) => {
        console.log("imageFile", imageFile); 
        if (imageFile) {
            const reader = new FileReader();
      
            reader.onloadend = async () => {
                // The result property contains the base64 string
                const result = reader.result;

                console.log("base64", result);

                const newFormatFile = {
                    ...imageFile, 
                    base64: result
                }
                
                setState(prevState => ({ ...prevState, imageFile: newFormatFile }))
            };
            // Read the file as a data URL, which returns a base64 representation of the file content
            reader.readAsDataURL(imageFile.file);
        }
    };

    const runAfterImageDelete = (file) => {
        console.log(file);

        setState(prevState => ({ ...prevState, imageFile: null }))
    }

    useEffect(() => {
        const calculateMostRecentLog = (securityLogs) => {
            if (typeof state.userDataRestricted !== null && typeof securityLogs !== "undefined" && securityLogs.length > 0) {
                return securityLogs[securityLogs.length - 1].date;
            } else {
                return "Unknown"
            }
        };

        console.log("mounted.");

        const configuration = {
            params: {
                uniqueId: authData.uniqueId,
                accountType: authData.accountType
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/gather/general/info/user`, configuration).then((res) => {
            if (res.data.message === "Successfully ran logic!") {
                console.log("res.data", res.data);

                const { user } = res.data;

                const paginatedPages = calculateTotalPages(typeof user.securityLogs !== "undefined" && user.securityLogs.length > 0 ? user.securityLogs.length : 0, 5);
                
                setState(prevState => ({ ...prevState, userDataRestricted: user, latestAuth: calculateMostRecentLog(user.securityLogs), totalPages: paginatedPages }))
            } else {
                console.log("err");
            }
        })

        const configurationTwo = {
            params: {
                uniqueId: authData.uniqueId,
                accountType: authData.accountType
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/gather/payment/methods/account`, configurationTwo).then((res) => {
            if (res.data.message === "Successfully ran logic!") {
                console.log("res.data", res.data);

                const { paymentMethods } = res.data;

                setState(prevState => ({ ...prevState, paymentMethods }))
            } else {
                console.log("err");
            }
        })
    }, []);

    const handleSubmission = (e) => {
        e.preventDefault();

        console.log("handleSubmission ran/clicked.");

        const {
            newProfilePic,
            instagram,
            username,
            email,
            twitter,
            firstName,
            selectedAddress,
            lastName
        } = state;

        const configuration = {
            newProfilePic,
            instagram,
            username,
            email,
            selectedAddress,
            twitter,
            firstName,
            lastName,
            uniqueId: authData.uniqueId,
            base64: typeof state.newProfilePic !== "undefined" && state.newProfilePic.length > 0 ? state.newProfilePic[0].data_url : null, 
            contentType: typeof state.newProfilePic !== "undefined" && state.newProfilePic.length > 0 ? state.newProfilePic[0].file.type : null, 
            filename: typeof state.newProfilePic !== "undefined" && state.newProfilePic.length > 0 ? state.newProfilePic[0].file.name : null
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/update/profile/settings`, configuration).then((res) => {
            if (res.data.message === "Cannot upload innapropriate images - try to reupload another image.") {
                NotificationManager.warning(`Cannot upload lewd or innaporpriate content!`, 'Innapropriate content is not allowed.', 3000);
            } else if (res.data.message === "Successfully submitted new profile information!") {
                NotificationManager.success(`We've successfully updated your profile information!`, 'Successfully added profile information.', 3000);

                const { user } = res.data;

                authentication(user);
                setState({
                    screen: "default",
                    newProfilePic: [],
                    instagram: "",
                    username: "",
                    email: "",
                    selectedAddress: null,
                    address: "",
                    twitter: "",
                    firstName: "",
                    lastName: ""
                });
            } else {
                console.log("err");

                NotificationManager.error('An unknown error has occurred processing your request.', 'Error occurred with request!', 3000);
            }
        })
    } 

    console.log("state", state);

    const changeToPrimaryCardMethod = (payment) => {
        console.log("payment id", payment.id);

        const configuration = {
            paymentID: payment.id,
            uniqueId: authData.uniqueId
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/change/primary/card/stripe/account`, configuration).then((res) => {
            if (res.data.message === "Successfully executed logic!") {
                console.log("res.data correct/success: ", res.data);

                NotificationManager.success(`Successfully changed your primary card to your account!`, 'Successfully changed primary card!', 3000);
            } else {
                console.log("res data error :", res.data);
            }
        })
    }

    const handleAddCardSubmit = async (e) => {
        if (typeof e !== "undefined") {
            e.preventDefault();
        };

        const cardElement = elements.getElement(CardElement);

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });
    
        if (error) {
            console.error('Error creating payment method:', error);

            NotificationManager.error("Error occurred with payment method!", 'Error adding PAYMENT METHOD.', 3000);
        } else {
            // Use the paymentMethod.id as the payment token and send it to your server
            console.log('Payment Method ID:', paymentMethod.id);

            const configuration = {
                paymentID: paymentMethod.id,
                uniqueId: authData.uniqueId
            };
    
            axios.post(`${process.env.REACT_APP_BASE_URL}/add/new/card/only/fetch/profile`, configuration).then((res) => {
                if (res.data.message === "Successfully executed logic!") {
                    console.log("res.data correct/success: ", res.data);

                    const { paymentMethods } = res.data;

                    setState(prevState => ({ ...prevState, paymentMethods }))

                    NotificationManager.success(`Successfully added your new card to your account!`, 'Successfully added card!', 3000);
                } else {
                    console.log("res data error :", res.data);
                }
            })
        }
    }

    const calculatePassDisabled = () => {
        const {
            oldPassword,
            newPassword,
            newPasswordReentry
        } = state;

        if ((typeof oldPassword !== "undefined" && oldPassword.length > 0) && (typeof newPassword !== "undefined" && newPassword.length > 0 && typeof newPasswordReentry !== "undefined" && newPasswordReentry.length > 0) && (newPasswordReentry === newPassword)) {
            return false;
        } else {
            return true;
        }
    } 

    const changePasswordAttempt = (e) => {
        e.preventDefault();

        console.log("changePasswordAttempt clicked/ran.");

        const {
            oldPassword,
            newPassword
        } = state;

        const configuration = {
            oldPassword,
            newPassword,
            uniqueId: authData.uniqueId
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/change/update/password/account`, configuration).then((res) => {
            if (res.data.message === "Successfully executed logic!") {
                console.log("res.data correct/success: ", res.data);

                const {  } = res.data;

                setState(prevState => ({ ...prevState,  oldPassword: "", newPassword: "", newPasswordReentry: "" }));

                NotificationManager.success(`Successfully changed/modified your password.!`, 'Successfully modified password, logging you out now..', 3000);

                setTimeout(() => {
                    authentication({});

                    navigate("/");
                }, 3000)
            } else if (res.data.message === "Original password do NOT match - please try your request again.") {
                NotificationManager.warning(res.data.message, 'Original password is NOT valid or matching.', 3000);
            } else {
                console.log("res data error :", res.data);
            }
        })
    };

    const handleChange = address => {
        setState(prevState => ({ ...prevState, address }));
    };
     
    const handleChangeTwo = address => {
        setState(prevState => ({ ...prevState, addressTwo: address }));
    };

    const handleSelectTwo = address => {
        geocodeByAddress(address).then(results => setState(prevState => ({ ...prevState, selectedAddressTwo: results[0], addressTwo: "" }))).then(latLng => console.log('Success', latLng)).catch(error => console.error('Error', error));
    };

    const handleSelect = address => {
        geocodeByAddress(address).then(results => setState(prevState => ({ ...prevState, selectedAddress: results[0], address: "" }))).then(latLng => console.log('Success', latLng)).catch(error => console.error('Error', error));
    };

    const handleSubmissionRegisterAdditional = async () => {
        console.log("handleSubmissionRegisterAdditional clicked/ran...");

        const cardElement = elements.getElement(CardElement);

        const { paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });

        console.log("paymentMethod", paymentMethod);

        const { isCardReadyTwo, captchaCompletedTwo, imageFile, selectedAddressTwo } = state;

        if (isCardReadyTwo === true && captchaCompletedTwo === true && imageFile !== null && selectedAddressTwo !== null && typeof paymentMethod !== "undefined") {
            const configuration = {
                uniqueId: authData.uniqueId,
                accountType: authData.accountType,
                paymentID: paymentMethod.id,
                imageFile,
                selectedAddressTwo
            };

            axios.post(`${process.env.REACT_APP_BASE_URL}/change/account/type`, configuration).then((res) => {
                if (res.data.message === "Successfully submitted information!") {
                    NotificationManager.success(`We've successfully updated your account type!`, 'Successfully updated account.', 3000);
                    
                    const { user } = res.data;

                    authentication(user);

                    setState(prevState => ({ ...prevState, captchaCompleted: false, screen: "default", imageFile: null, selectedAddressTwo: null, captchaCompletedTwo: false, isCardReadyTwo: false }));
                } else {
                    setState(prevState => ({ ...prevState, isLoading: false }));

                    console.log("err");
                    
                    NotificationManager.error('An unknown error has occurred processing your request.', 'Error occurred with request!', 3000);
                }
            })
        } else {
            NotificationManager.warning('Complete all the required inputs before proceeding...', 'Complete the required information first!', 3000);
        }
    }

    const renderAppropriateView = () => {
        const { screen } = state;

        if (screen === "default") {
            return (
                <Fragment>
                    <form className="form-horizontal">
                        <fieldset className="fieldset">
                        <h3 className="fieldset-title">Personal Info</h3>
                        <p className="lead">Your bidder ID: {authData.uniqueId}</p>
                        <div className="form-group avatar">
                            <div className="form-inline col-md-10 col-sm-9 col-xs-12">
                                <ImageUploading
                                    multiple={false}
                                    value={state.newProfilePic}
                                    onChange={(images) => setState(prevState => ({ ...prevState, newProfilePic: images }))}
                                    maxNumber={8}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        onImageUpload,
                                        onImageRemove,
                                        isDragging,
                                        imageList,
                                        onImageUpdate,
                                        dragProps
                                    }) => (
                                        <Fragment>
                                            {typeof state.newProfilePic !== "undefined" && state.newProfilePic.length > 0 ? <Fragment>
                                                <div style={{ marginTop: "22.25px", marginBottom: "20px" }} className="upload__image-wrapper">
                                                    <img className="imageupload" src={state.newProfilePic[0].data_url} />
                                                </div>
                                            </Fragment> : <Fragment>
                                                <div style={{ marginTop: "22.25px" }} className="upload__image-wrapper">
                                                    <div style={isDragging ? { color: 'red' } : undefined} {...dragProps} onClick={() => {
                                                        onImageUpload();
                                                        
                                                        setState(prevState => ({ ...prevState, images: imageList }))
                                                    }}><img className="imageupload" src={require("../../assets/icons/dragdrop.jpg")} /></div>
                                                    &nbsp;
                                                </div>
                                            </Fragment>}
                                        </Fragment>
                                    )}
                                </ImageUploading>
                            </div>
                        </div>
                        <div className="form-group">
                            <label style={{ marginTop: "4.25px" }} className="col-md-2 col-sm-3 col-xs-12 control-label">
                            Username
                            </label>
                            <div className="col-md-10 col-sm-9 col-xs-12">
                            <input
                                type="text"
                                value={state.username}
                                onChange={(e) => setState(prevState => ({ ...prevState, username: e.target.value }))}
                                className="form-control"
                                style={{ marginTop: "7.25px" }}
                            />
                            </div>
                        </div>
                        <div className="form-group">
                            <label style={{ marginTop: "4.25px" }} className="col-md-2 col-sm-3 col-xs-12 control-label">
                            First Name
                            </label>
                            <div className="col-md-10 col-sm-9 col-xs-12">
                            <input
                                type="text"
                                className="form-control"
                                value={state.firstName}
                                onChange={(e) => setState(prevState => ({ ...prevState, firstName: e.target.value }))}
                                style={{ marginTop: "7.25px" }}
                            />
                            </div>
                        </div>
                        <div className="form-group">
                            <label style={{ marginTop: "4.25px" }} className="col-md-2 col-sm-3 col-xs-12 control-label">
                            Last Name
                            </label>
                            <div className="col-md-10 col-sm-9 col-xs-12">
                            <input
                                type="text"
                                className="form-control"
                                value={state.lastName}
                                onChange={(e) => setState(prevState => ({ ...prevState, lastName: e.target.value }))}
                                style={{ marginTop: "7.25px" }}
                            />
                            </div>
                        </div>
                        </fieldset>
                        <fieldset className="fieldset">
                        <div className="row" style={{ paddingTop: 15 }}>
                            <label style={{ marginTop: "4.25px" }} className="col-md-2 col-sm-3 col-xs-12 control-label">
                                Address
                            </label>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <PlacesAutocomplete
                                    value={state.address}
                                    onChange={handleChange}
                                    onSelect={handleSelect}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Change your address (search for address) ...',
                                                className: 'location-search-input form-control spacing-input',
                                                autocomplete: 'off',
                                                name: "address"
                                            })}
                                            autoComplete="off"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <p>{typeof state.selectedAddress !== "undefined" && state.selectedAddress !== null && _.has(state.selectedAddress, "formatted_address") ? state.selectedAddress.formatted_address : ""}</p>
                            </div>
                        </div>
                        </fieldset>
                        <hr />
                        <div className="form-group">
                        <br />
                        <div className="col-md-10 col-sm-9 col-xs-12 col-md-push-2 col-sm-push-3 col-xs-push-0">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                style={{ width: "62.25%" }}
                                onClick={handleSubmission}
                            >
                                {width <= 775 ? "Submit" : "Submit New Information"}
                            </button>
                        </div>
                        <br />
                        <div className="form-group">
                            <label style={{ marginTop: "4.25px", fontWeight: "bold" }} className="col-md-12  col-sm-3 col-xs-12 control-label">
                            Change your account password
                            </label>
                            <hr />
                            <div className="col-md-10 col-sm-9 col-xs-12">
                            <label style={{ marginTop: "4.25px" }} className="col-md-12  col-sm-3 col-xs-12 control-label">
                                Old password
                            </label>
                            <input
                                type={"password"}
                                placeholder="Old Password"
                                className="form-control"
                                autocomplete="false"
                                value={state.oldPassword}
                                onChange={(e) => setState(prevState => ({ ...prevState, oldPassword: e.target.value }))}
                                style={{ marginTop: "7.25px" }}
                            />
                            <label style={{ marginTop: "4.25px" }} className="col-md-12  col-sm-3 col-xs-12 control-label">
                                New Password
                            </label>
                            <input
                                type={"password"}
                                className="form-control"
                                placeholder="New Password"
                                value={state.newPassword}
                                onChange={(e) => setState(prevState => ({ ...prevState, newPassword: e.target.value }))}
                                style={{ marginTop: "7.25px" }}
                            />
                            <label style={{ marginTop: "4.25px" }} className="col-md-12  col-sm-3 col-xs-12 control-label">
                                New Password Re-entry
                            </label>
                            <input
                                type={"password"}
                                className="form-control"
                                placeholder="New Password Re-Entry"
                                value={state.newPasswordReentry}
                                onChange={(e) => setState(prevState => ({ ...prevState, newPasswordReentry: e.target.value }))}
                                style={{ marginTop: "7.25px" }}
                            />
                            </div>
                            <hr />
                            <div className="col-md-10 col-sm-9 col-xs-12 col-md-push-2 col-sm-push-3 col-xs-push-0">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    style={{ width: "62.25%" }}
                                    disabled={calculatePassDisabled()}
                                    onClick={changePasswordAttempt}
                                >
                                    {width <= 775 ? "Change" : "Change Password"}
                                </button>
                            </div>
                        </div>
                        </div>
                    </form>
                </Fragment>
            )
        } else if (screen === "bookmarked") { 
            return (
                <Fragment>
                    <p className="text-center">Click/long-press a ‘bookmark’ to delete a previously saved bookmark.</p>
                    {typeof state.userDataRestricted.favoritesList !== "undefined" && state.userDataRestricted.favoritesList !== null && state.userDataRestricted.favoritesList.length > 0 ? state.userDataRestricted.favoritesList.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((item, index) => {
                        return (
                            <Fragment key={index}>
                                <section 
                                    onMouseDown={() => startPressTimer(item)}
                                    onMouseUp={clearPressTimer}
                                    onMouseLeave={clearPressTimer}
                                    onTouchStart={() => startPressTimer(item)}
                                    onTouchEnd={clearPressTimer}
                                    onTouchMove={clearPressTimer}
                                    style={{ backgroundColor: "#fff", marginBottom: 50, border: "1px solid lightgrey" }} className="search-result-item"
                                >
                                    <a className="image-link centered">
                                        <img
                                        className="image"
                                        id="specialimmmmg"
                                        src={item.listingImages[0].location}
                                        />
                                    </a>
                                    <div className="search-result-item-body">
                                        <div className="row">
                                        <div className="col-sm-9">
                                            <h6 className="search-result-item-heading">
                                            <a style={{ fontWeight: "bold" }}>ID: {item.listingID}</a>{" "}
                                            <span className="badge bg-danger fw-normal pull-left" style={{ marginTop: 10 }}>
                                                Bookmarked: {moment(item.timestamp).fromNow()}
                                            </span>
                                            </h6>
                                            <p className="info text-left" style={{ textAlign: "left", fontWeight: "bold", position: "absolute", bottom: 10, right: 10 }}>Title: {item.listingTitle.slice(0, 70)}{typeof item.listingTitle !== "undefined" && item.listingTitle.length >= 70 ? "..." : ""}</p>
                                            <p style={width <= 775 ? { textAlign: "left", position: "absolute", bottom: 10, left: 0 } : { textAlign: "left", position: "absolute", bottom: 10, left: 210 }} className="description text-left">
                                                {`Lot Type: ${item.listingLotType}`}
                                            </p>
                                        </div>
                                        <div className="col-sm-3 text-align-center">
                                            {/* <p className="value3 mt-sm">$10, 300</p> */}
                                            {/* <p className="fs-mini text-muted">PER WEEK</p> */}
                                            <a style={{ color: "#fff", marginTop: 10 }} onClick={() => navigate(`/lot_page/${item.listingID}`)} className="btn btn-primary btn-info btn-sm">
                                                View
                                            </a>
                                        </div>
                                        </div>
                                    </div>
                                </section>
                            </Fragment>
                        );
                    }) : <Fragment>
                        <div className="centered">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="row">
                                    <p style={{ color: "#000", fontWeight: "bold" }} className="lead">There aren't any results yet, 'bookmark' some listings/auctions and they'll be shown/populated here...</p>
                                </div>
                                <div className="row">
                                    <img style={{ width: 375, height: 375, objectFit: "contain" }} src={require("../../assets/no-data.png")} />
                                </div>
                            </div>
                        </div>
                    </Fragment>}
                </Fragment>
            );
        } else if (screen === "security-logs") {
            return (
                <Fragment>
                    <div className="box shadow-sm rounded bg-white mb-3">
                        <div className="box-title border-bottom p-3">
                            <h6 className="m-0">Security/Authentication logs (each represents one successful login)</h6>
                        </div>
                        <div className="box-body p-0">
                            {typeof authData.securityLogs !== "undefined" && authData.securityLogs.length > 0 ? authData.securityLogs.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(state.currentPage, state.currentPage + 5).map((log, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div style={{ marginBottom: 12.25 }} className="p-3 d-flex align-items-ed border-bottom osahan-post-header">
                                            <div className="font-weight-bold mr-3" style={{ marginLeft: 15 }}>
                                                <div className="text-truncate">Browser: {log.browser}</div>
                                                <div className="text-truncate">Verison: {log.version}</div>
                                                <div className="text-truncate">Operating System: {log.os}</div>
                                                <hr />
                                                <div className="small">
                                                    {log.source}
                                                </div>
                                                <hr />
                                                <div className="text-truncate">IP Address: {log.ipAddress}</div>
                                                <div className="text-truncate">Platform: {log.platform}</div>
                                            </div>
                                            <span className="ml-auto mb-auto">
                                                <br />
                                                <div className="text-right text-muted pt-1" style={{ position: "absolute", right: 42.5 }}>{moment(new Date(log.date)).fromNow(false)}</div>
                                            </span>
                                        </div>
                                    </Fragment>
                                );
                            }) : <Fragment>
                                <div className="centered">
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                        <div className="row">
                                            <p style={{ color: "#000", fontWeight: "bold" }} className="lead">There aren't any security logs yet, after a few authentication's you'll see login attempts here...</p>
                                        </div>
                                        <div className="row">
                                            <img style={{ width: 375, height: 375, objectFit: "contain" }} src={require("../../assets/no-data.png")} />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>}
                        </div>
                        <br />
                        <ResponsivePagination
                            current={state.currentPage}
                            total={state.totalPages}
                            onPageChange={(page) => setState(prevState => ({ ...prevState, currentPage: page }))}
                        />
                        <br />
                    </div>
                </Fragment>
            );
        }  else if (screen === "upgrade-account") {
            return (
                <Fragment>
                    <div className="container">
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <p className="leader">Enter your card details to verify/validate your account as a bidder, this will be your primary card on file.</p>
                                    <div className="row">
                                        <CardElement tabIndex="11" onChange={(val) => {
                                            setState(prevState => ({ ...prevState, isCardReadyTwo: val.complete }));
                                        }} className="form-control spacing-input" />
                                    </div>
                                    <div className="row" style={{ paddingTop: 15 }}>
                                        <div className="col-md-12 col-lg-12 col-sm-12">
                                        <PlacesAutocomplete
                                            value={state.addressTwo}
                                            tabIndex="12"
                                            onChange={handleChangeTwo}
                                            onSelect={handleSelectTwo}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Search for your home address ...',
                                                            className: 'location-search-input form-control spacing-input',
                                                            autocomplete: 'off',
                                                            name: "addresstwo"
                                                        })}
                                                        autoComplete="off"
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {typeof state.selectedAddressTwo !== "undefined" && state.selectedAddressTwo !== null ? <p className="lead" style={{ color: "#800020", textAlign: "left", marginTop: 11.25, fontWeight: "400" }}>{state.selectedAddressTwo.formatted_address}</p> : null}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                                <div className="row" style={{ marginTop: 35 }}>
                                    <p>Upload an image of your drivers license or identification.</p>
                                </div>
                                <div className="center centered">
                                    <div className="row">
                                        <ImageUploader
                                            onFileAdded={(img) => handleFileInputChange(img)} // function that runs to confirm that your image actually exists
                                            onFileRemoved={(img) => runAfterImageDelete(img)} // function runs on once you delete your image
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12">
                                <div className="centered">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_KEY} // containsExplicitWords()
                                        onChange={(value) => value ? setState(prevState => ({ ...prevState, captchaCompletedTwo: true })) : null}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="center centered">
                            <div style={{ marginTop: 27.25 }} className="btn">
                                <a style={{ color: "#fff" }} onClick={handleSubmissionRegisterAdditional}>Upgrade To Bidder Account!</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else if (screen === "payment") {
            return (
                <Fragment>
                    <div className="container">
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                {typeof state.paymentMethods !== "undefined" && state.paymentMethods.length > 0 ? state.paymentMethods.map((payment, index) => {
                                    console.log("payment", payment);
                                    return (
                                        <Fragment key={index}>
                                            <div className="col-md-6">
                                                <div className="payment-card">
                                                    <i className="fa fa-cc-visa payment-icon-big text-success" />
                                                    <h4>**** **** **** {payment.card.last4}</h4>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <small>
                                                            <strong>Expiry date:</strong> {payment.card.exp_month}/{payment.card.exp_year}
                                                            </small>
                                                        </div>
                                                        <div className="col-sm-6 text-right">
                                                            <small>
                                                            <strong>Type:</strong> {payment.type === "card" ? "Card (Debit/Credit)" : "Unknown"}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="col-sm-6">
                                                            <small>
                                                            <strong>Expiry date:</strong> {payment.card.exp_month}/{payment.card.exp_year}
                                                            </small>
                                                        </div>
                                                    </div> */}
                                                    <button style={{ width: "100%", marginTop: 20 }} onClick={(e) => {
                                                        e.preventDefault();

                                                        changeToPrimaryCardMethod(payment);
                                                    }} className="btn btn-primary">
                                                        {width <= 775 ? "Set Primary" : "Change to primary!"}
                                                    </button>
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                }) : null}
                            </div>
                            {_.has(authData, "stripeAccountDetails") ? <div className="row">
                                <div className="panel-collapse in">
                                    <div className="panel-body">
                                        <div className="row" style={{ marginBottom: 22.25 }}>
                                            <h2 style={{ textAlign: "left" }}>About This Page</h2>
                                            <p className="m-t">
                                                Welcome to the payment management page, where you have full control over your financial transactions. This dedicated space empowers you to effortlessly handle various aspects of your payments. Whether you need to make new payments, manage existing ones, or update your primary payment method, this page is your central hub for all things related to financial transactions. Explore the intuitive features designed to streamline your payment experience, providing convenience and flexibility at your fingertips. From adding new payment methods to seamlessly changing your primary option, this page is your gateway to efficient and secure payment management.
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
                                                <form role="form" id="payment-form">
                                                <CardElement onChange={(val) => {
                                                    console.log("val", val);
                                                    setState(prevState => ({ ...prevState, isCardReady: val.complete }));
                                                }} className="form-control spacing-input" />
                                                {/* <div className="row" style={{ marginTop: 22.25 }}>
                                                    <div className="col-xs-12">
                                                    <div className="form-group">
                                                        <label>CARD NUMBER</label>
                                                        <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="number"
                                                            placeholder="Valid Card Number"
                                                            required=""
                                                            onFocus={handleInputFocus}
                                                            onChange={handleInputChange}
                                                            value={state.cardNumber}
                                                        />
                                                        <span className="input-group-addon">
                                                            <i className="fa fa-credit-card" />
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ marginTop: 22.25 }}>
                                                    <div className="col-xs-7 col-md-7">
                                                    <div className="form-group">
                                                        <label>EXPIRATION DATE</label>
                                                        <input
                                                        type="text"
                                                        className="form-control"
                                                        name="expiry"
                                                        placeholder="MM / YY"
                                                        onFocus={handleInputFocus}
                                                        onChange={handleInputChange}
                                                        value={state.cardNumber}
                                                        required=""
                                                        />
                                                    </div>
                                                    </div>
                                                    <div className="col-xs-5 col-md-5 pull-right">
                                                    <div className="form-group">
                                                        <label>CV CODE</label>
                                                        <input
                                                        type="text"
                                                        className="form-control"
                                                        name="cvc"
                                                        onFocus={handleInputFocus}
                                                        onChange={handleInputChange}
                                                        placeholder="CVC"
                                                        required=""
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ marginTop: 22.25 }}>
                                                    <div className="col-xs-12">
                                                    <div className="form-group">
                                                        <label>NAME OF CARD</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            onFocus={handleInputFocus}
                                                            onChange={handleInputChange}
                                                            placeholder="NAME AND SURNAME"
                                                        />
                                                    </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <button onClick={handleAddCardSubmit} style={{ marginTop: 22.25, width: "100%" }} disabled={state.isCardReady === true ? false : true} className="btn btn-primary" type="submit">
                                                            Add your card!
                                                        </button>
                                                    </div>
                                                </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </Fragment>
            );
        }
    };

    const calculateAccountType = () => {
        if (authData.accountType === "standard") {
            return "Seller/Standard Account";
        } else {
            return "Buyer/Bidder Account";
        }
    };

    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="container">
                <div className="view-account">
                    <section className="module">
                    <div className="module-inner">
                        <div className="side-bar">
                        <div className="user-info">
                            <img
                            className="img-profile img-circle img-responsive center-block"
                            style={{ objectFit: "contain" }}
                            src={typeof authData.profilePic !== "undefined" && authData.profilePic !== null ? `${process.env.REACT_APP_BASE_ASSET_URL}/${_.has(authData.profilePic, "link") ? authData.profilePic.link :`https://ui-avatars.com/api/?name=${authData.firstName}+${authData.lastName}` }` : `https://ui-avatars.com/api/?name=${authData.firstName}+${authData.lastName}`}
                            alt=""
                            />
                            <ul className="meta list list-unstyled">
                            <li className="name">
                                {`${authData.firstName} ${authData.lastName}`}
                                <label className="label label-info" style={{ color: "blue" }}>{calculateAccountType()}</label>
                            </li>
                            <li className="email">
                                <div>{authData.email}</div>
                            </li>
                            <li className="activity">Last logged in: {state.latestAuth !== null ? moment(state.latestAuth).fromNow(false) : "----"}</li>
                            <li className="activity">Address: {authData.selectedAddress !== null  && _.has(authData.selectedAddress, "formatted_address") && typeof authData.selectedAddress.formatted_address !== "undefined" ? authData.selectedAddress.formatted_address : "----"}</li>
                            <li className="activity">Phone Num: {authData.phoneNumber}</li>
                            </ul>
                        </div>
                        <nav className="side-menu">
                            <ul className="nav">
                            <li className={state.screen === "default" ? "active" : ""}>
                                <a onClick={() => setState(prevState => ({ ...prevState, screen: "default" }))} className="hover">
                                <span className="fa fa-user" /> Profile
                                </a>
                            </li>
                            <li className={state.screen === "bookmarked" ? "active" : ""}>
                                <a onClick={() => setState(prevState => ({ ...prevState, screen: "bookmarked" }))} className="hover">
                                <span className="fa fa-cog" /> Bookmarked Listing(s)
                                </a>
                            </li>
                            <li className={state.screen === "payment" ? "active" : ""}>
                                <a onClick={() => setState(prevState => ({ ...prevState, screen: "payment" }))} className="hover">
                                <span className="fa fa-cog" /> Payment Related
                                </a>
                            </li>
                            
                            {/* <li className={state.screen === "billing" ? "active" : ""}>
                                <a onClick={() => setState(prevState => ({ ...prevState, screen: "billing" }))} style={{ color: "darkgrey" }} className="hover">
                                <span className="fa fa-credit-card" /> Billing
                                </a>
                            </li> */}
                            <li className={state.screen === "security-logs" ? "active" : ""}>
                                <a onClick={() => setState(prevState => ({ ...prevState, screen: "security-logs" }))} className="hover">
                                <span className="fa fa-envelope" /> Security Log(s)
                                </a>
                            </li>
                            {authData.accountType === "standard" ? <li className={state.screen === "upgrade-account" ? "active" : ""}>
                                <a onClick={() => setState(prevState => ({ ...prevState, screen: "upgrade-account" }))} className="hover">
                                <span className="fa fa-envelope" /> Upgrade Account
                                </a>
                            </li> : null}
                            </ul>
                        </nav>
                        </div>
                        <div className="content-panel">
                        <h2 className="title">
                            Profile<span className="pro-label label label-warning" style={{ backgroundColor: "darkblue", color: "#fff", borderRadius: 7.25 }}>UPDATE</span>
                        </h2>
                            {renderAppropriateView()}
                        </div>
                    </div>
                    </section>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null
    }
};

export default connect(mapStateToProps, { authentication })(ProfileSettingsHelper);