import React, { Fragment, useState } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import { Link, useNavigate } from "react-router-dom";
import ResponsivePagination from 'react-responsive-pagination';
import { useEffect } from "react";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';
import axios from "axios";
import ContentLoader from 'react-content-loader'
import {
    useWindowSize
} from '@react-hook/window-size';
import "./styles.css";
import moment from "moment";
import Downshift from 'downshift';
// import Magnifier from "react-magnifier";
import { confirmAlert } from 'react-confirm-alert';
import Datetime from 'react-datetime';
import { Range } from 'react-range';
import Select from 'react-select';
import ImageGallery from "react-image-gallery";
import { Modal } from 'react-responsive-modal';


const optionsCategory = [{
    label: "All Listings",
    value: "all"
}, {
    label: "Violin",
    value: "violin"
}, {
    label: "Viola",
    value: "viola"
}, {
    label: "Cello",
    value: "cello"
}, {
    label: "Violin Bow",
    value: "violin-bow"
}, {
    label: "Viola Bow",
    value: "viola-bow"
}, {
    label: "Cello Bow",
    value: "cello-bow"
}, {
    label: "Bass Bow",
    value: "bass-bow"
}, {
    label: "Miscellaneous",
    value: "miscellaneous"
}];

const ITEMS_PER_PAGE = 25;

const calculateTotalPages = (totalResults, itemsPerPage) => {
    return Math.ceil(totalResults / itemsPerPage);
}

const IndividualAuctionViewHelper = ({ authData }) => {
    const navigate = useNavigate();
    const [width, height] = useWindowSize()
    const [ state, setState ] = useState({
        currentPage: 0,
        listings: [],
        allListings: [],
        totalPages: 0,
        checked: 0,
        isModalOpen: false,
        images: [
            {
              original: "https://picsum.photos/id/1018/1000/600/",
              thumbnail: "https://picsum.photos/id/1018/250/150/"
            },
            {
              original: "https://picsum.photos/id/1015/1000/600/",
              thumbnail: "https://picsum.photos/id/1015/250/150/"
            },
            {
              original: "https://picsum.photos/id/1019/1000/600/",
              thumbnail: "https://picsum.photos/id/1019/250/150/"
            },
            {
                original: "https://picsum.photos/id/1018/1000/600/",
                thumbnail: "https://picsum.photos/id/1018/250/150/",
              },
              {
                original: "https://picsum.photos/id/1015/1000/600/",
                thumbnail: "https://picsum.photos/id/1015/250/150/",
              },
              {
                original: "https://picsum.photos/id/1019/1000/600/",
                thumbnail: "https://picsum.photos/id/1019/250/150/",
              },
        ],
        selectedCategory: null,
        priceValuesTwo: [100000],
        priceValuesOne: [1],
        startDate: null,
        endDate: null
    })

    console.log("state", state);

    useEffect(() => {
        const configuration = {}

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
                const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);

                setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages }));

                // NotificationManager.success('Successfully fetched/gathered live auctions!', 'Successfully gathered listings.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, []);

    const handleSelectionChangeCategory = (value) => {
        console.log("handleSelectionChangeCategory clicked/ran...");

        const configuration = {
            params: {
                selectedCategory: value
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/listings/by/category`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;

                const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);

                setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages }));
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    };

    useEffect(() => {
        // const itemsPerPage = 10;
        // const indexOfLastItem = state.currentPage * itemsPerPage;
        // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentListings = state.allListings.slice(state.currentPage * ITEMS_PER_PAGE, (state.currentPage * ITEMS_PER_PAGE) + ITEMS_PER_PAGE);

        setState(prevState => ({ ...prevState, listings: currentListings }));
    }, [state.currentPage]);

    console.log("listings", state.listings);

    const findHighestBid = (arr) => {
        if (arr.length === 0) {
          return 0; // or handle the empty array case as needed
        }
        const highestBid = arr.reduce((maxBid, obj) => (obj.bidAmount > maxBid ? obj.bidAmount : maxBid), arr[0].bidAmount);
        return highestBid;
    }

    const addToFavoritesListDB = (auctionID) => {
        console.log("addToFavoritesListDB clicked/ran...");

        const configuration = {
            auctionID,
            uniqueId: authData.uniqueId
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/add/to/favorites/list/personal`, configuration).then((res) => {
            if (res.data.message === "Successfully added to favorites list!") {
                console.log("res.data", res.data);

                NotificationManager.success(`Successfully added to your 'favorites' list.`, 'Successfully added to favorites list!', 3000);
            } else if (res.data.message === "You've already 'favorited' this listing.") {
                NotificationManager.info("You've ALREADY bookmarked/favorited this listing - no action taken.", "Already 'bookmarked' this listing!", 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }

    const handleUpdate = (listing) => {
        const promise = new Promise((resolve, reject) => {
            const imageArr = [];

            for (let index = 0; index < listing.images.length; index++) {
                const image = listing.images[index];
                
                imageArr.push({
                    original: image.location,
                    thumbnail: image.location
                })
                if ((listing.images.length - 1) === index) {
                    resolve(imageArr);
                }
            }
        })

        promise.then((passedImages) => {
            setState(prevState => ({ ...prevState, isModalOpen: true, images: passedImages }))
        })
    }

    const addCommasToNumber = (number) => {
        // Convert the number to a string
        const numberString = number.toString();
        // Use a regular expression to add commas
        const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');    
        return formattedNumber;
    }

    const renderMainContent = () => {

        const renderConditionalWhenReadyOrNot = (listing, formattedDuration) => {
            const startDate = new Date(listing.startDate).getTime();
            let now = new Date().getTime();
            let timeRemaining = startDate - now;
        
            const calculateRemainingTime = () => {
                const now = new Date().getTime();
                const targetTime = new Date(listing.startDate).getTime();
                const remainingTime = targetTime - now;
        
                if (remainingTime < 0) {
                    // If the target time has passed, return zeros
                    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
                } else {
                    // Calculate days, hours, minutes, and seconds remaining
                    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        
                    return { days, hours, minutes, seconds };
                }
            };
        
            const { days, hours, minutes, seconds } = calculateRemainingTime();
        
            if (timeRemaining > 0) {
                // Countdown is active
                return (
                    <Fragment>
                        <p className="text-left" style={{ float: "left", textAlign: "left", fontWeight: "bolder" }}>Starts in:</p>
                        <p className="text-left" style={{ float: "left", textAlign: "left" }}>
                            {days}d {hours}h {minutes}m {seconds}s
                        </p>
                    </Fragment>
                );
            } else {
                // Countdown is not active
                return (
                    <Fragment>
                        <p className="text-left" style={{ float: "left", textAlign: "left", fontWeight: "bolder" }}>Time Left</p>
                        <p className="text-left" style={{ float: "left", textAlign: "left" }}>
                            {formattedDuration}
                        </p>
                    </Fragment>
                );
            }
        };
        if (state.checked === 0) {
            return (
                <div id="table" className="auc-table-container">
                    <table className="auc-table">
                        <tbody>
                        <p style={{ fontWeight: "bold", textAlign: "left" }}>(<em style={{ color: "green" }}>GREEN = reserve met</em> | <em style={{ color: "red" }}>RED = reserve not met</em>)</p>
                        {/* Rows will be dynamically added based on your data */}
                        {typeof state.allListings !== "undefined" && state.allListings.length > 0 ? state.allListings.slice(state.currentPage * ITEMS_PER_PAGE, (state.currentPage * ITEMS_PER_PAGE) + ITEMS_PER_PAGE).map((listing, index) => {
                            const highestBidAmount = findHighestBid(listing.bidsArray);
                            console.log("highestBidAmount", listing.bidPrice);
                            let now = moment(new Date());
                            let end = moment(new Date(listing.endDate));
                            let duration = moment.duration(end.diff(now));
                            let days = duration.days();
                            let hours = duration.hours();
                            let minutes = duration.minutes();
                            let formattedDuration = `${days}d ${hours}h ${minutes}m`;

                            return (
                                <Fragment key={index}>
                                    <tr onClick={() => navigate(`/lot_page/${listing.id}`)} className="row hover" id="lot1">
                                        <div style={{ float: "left" }} className="row">
                                            <p className="text-left" style={{ textAlign: "left", fontWeight: "bold" }}>Lot #: {listing.id.split("-")[0]}</p>
                                        </div>
                                        <div onClick={() => {
                                            handleUpdate(listing);
                                        }} className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12" key={index}> {/* className="image-slider-custom" */}
                                            <img className="avatar-md img-thumbnail" style={{ minWidth: "100%", minHeight: "100%", objectFit: "contain" }} src={listing.images[0].location} />
                                            {/* <img src={listing.images[0].location} style={{ objectFit: "contain" }} alt="Lot Img" /> */}
                                        </div>
                                        <td className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                            <a className="hover" onClick={() => navigate(`/lot_page/${listing.id}`)}>
                                                {listing.title}
                                            </a>
                                            {/* <p>(GREEN = reserve met | RED = reserve not met)</p> */}
                                        </td>
                                        <td className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                            <p className="text-left" style={{ float: "left", textAlign: "left", fontWeight: "bolder" }}># Bids</p>
                                            <p className="text-left" style={{ float: "left", textAlign: "left" }}>{listing.bidsArray.length} current bid(s)</p>
                                        </td>
                                        <td className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                            <p className="text-left" style={{ float: "left", textAlign: "left", fontWeight: "bolder" }}>Amount</p>
                                            <p className="text-left" style={{ float: "left", textAlign: "left" }}>$ {Math.floor(Number(listing.reserveBidPrice)) <= Math.floor(Number(highestBidAmount)) ? <em style={{ color: "green" }}>{typeof highestBidAmount !== "undefined" && highestBidAmount !== null && highestBidAmount > 0 ? addCommasToNumber(highestBidAmount) : addCommasToNumber(Math.floor(Number(listing.bidPrice)))}</em> : <em style={{ color: "red" }}>{typeof highestBidAmount !== "undefined" && highestBidAmount !== null && highestBidAmount > 0 ? addCommasToNumber(highestBidAmount) : addCommasToNumber(Math.floor(Number(listing.bidPrice)))}</em>}</p>
                                        </td>
                                        <td className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                            {renderConditionalWhenReadyOrNot(listing, formattedDuration)}
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        }) : <div style={{ margin: 32.25 }}>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                        </div>}
                        {/* Add more rows as needed */}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <Fragment>
                    <div className="row">
                    {typeof state.allListings !== "undefined" && state.allListings.length > 0 ? state.allListings.slice(state.currentPage * ITEMS_PER_PAGE, (state.currentPage * ITEMS_PER_PAGE) + ITEMS_PER_PAGE).map((listing, index) => {
                            const highestBidAmount = findHighestBid(listing.bidsArray);
                            return (
                                <Fragment key={index}>
                                    <div style={{ paddingTop: 15 }} className="col-xl-6 col-md-6 col-lg-6 col-sm-12">
                                        <div className="card cardddddcustom">
                                            <div className="card-body">
                                                {/* <div className="float-end">
                                                    <a
                                                        className="font-size-16"
                                                        href="#"
                                                        role="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                    >
                                                        <img className="iconed-three" src={require("../../../assets/icons/infogreen.png")} />
                                                    </a>
                                                </div> */}
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <img className="avatar-md rounded-circle img-thumbnail" style={{ width: "235px", height: "235px", objectFit: "contain" }} src={listing.images[0].location} />
                                                        {/* <img
                                                            src={listing.images[0].location}
                                                            alt=""
                                                            className="avatar-md rounded-circle img-thumbnail"
                                                        /> */}
                                                    </div>
                                                    <div className="flex-1 ms-3">
                                                    <h5 className="font-size-16 mb-1">
                                                        <a href="#" className="text-dark">
                                                            {listing.title.slice(0, 55)}{listing.title.length >= 55 ? "..." : ""}
                                                        </a>
                                                    </h5>
                                                    <span className="badge badge-soft-success mb-0">
                                                        {index % 2 === 0 ? "Bidding!" : "Buy-it-now."}
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="mt-3 pt-1 row">
                                                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                                                        <p style={{ textDecorationLine: "underline", textAlign: "center" }} className="mb-0 burgendy-text">
                                                        Current Bid: <b style={{ color: "red" }}><span style={{ color: "black", textAlign: "left" }}>${typeof highestBidAmount !== "undefined" && highestBidAmount !== null ? highestBidAmount.toFixed(2) : "0"}</span></b>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                                                        <p style={{ textDecorationLine: "underline", textAlign: "center" }} className="row mb-0 burgendy-text mt-0">
                                                            # Of Bids:<span style={{ color: "black", textAlign: "left" }}>{listing.bidsArray.length} current bid(s)</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                                                        <p style={{ textDecorationLine: "underline", textAlign: "center" }} className="row mb-0 mt-0 burgendy-text">
                                                            Time Left / Ends: <span style={{ color: "black", textAlign: "left" }}>{moment(listing.endDate).fromNow()}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex gap-2 pt-4">
                                                    <button type="button" onClick={() => {
                                                        confirmAlert({
                                                            title: `Save to your 'favorite list'?`,
                                                            message: 'Are you sure you want to save this as a favorite?',
                                                            buttons: [
                                                                {
                                                                    label: 'Yes, Save!',
                                                                    onClick: () => {
                                                                        console.log("yes save");
                                                
                                                                        addToFavoritesListDB(listing.id)
                                                                    }
                                                                },
                                                                {
                                                                    label: `No, Don't save`,
                                                                    onClick: () => {
                                                                        console.log("no dont save");
                                                                    }
                                                                }
                                                            ]
                                                        });
                                                    }} className="btn-soft-primary btn-sm w-50">
                                                        Bookmark
                                                    </button>
                                                    <button type="button" onClick={() => navigate(`/lot_page/${listing.id}`)} className="btn-primary-custom btn-sm w-50">
                                                        View Listing
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        }) : <div style={{ margin: 32.25 }}>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                        </div>}
                    </div>
                </Fragment>
            );
        }
    }

    console.log("state.currentPage", state.currentPage);

    const searchLowerValues = (values) => {
        console.log("onFinalChange values: ", values);

        const configuration = {
            params: {
                lowestValue: values[0],
                highestValue: state.priceValuesTwo[0]
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions/by/price`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
                const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);

                setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages }));

                // NotificationManager.success('Successfully fetched/gathered live auctions!', 'Successfully gathered listings.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    };
    
    const searchHigherValues = (values) => {
        console.log("onFinalChange values: ", values);

        const configuration = {
            params: {
                lowestValue: state.priceValuesOne[0],
                highestValue: values[0]
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions/by/price`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
                const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);

                setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages }));

                // NotificationManager.success('Successfully fetched/gathered live auctions!', 'Successfully gathered listings.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    };
 
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <Modal classNames="modalstretch" open={state.isModalOpen} onClose={() => setState(prevState => ({ ...prevState, isModalOpen: false }))} center>
                <ImageGallery items={state.images} />
            </Modal>
            <div className="container-fluid">
                <h2 className="text-center" id="onlyshowonmobile">Current Lots/Listings</h2>{" "}
                <div className="auc-header">
                    <form id="auc-form">
                    <h2 className="text-center" id="movemobile">Current Lots/Listings</h2>{" "}
                    {/* Replace with Date/Name of the Auction as needed */}
                    </form>
                    <div className="view-form">
                        <p style={{ marginTop: 27.5 }}>View Type:</p>
                        <div className="radio-container">
                            <input
                                type="radio"
                                id="type-table"
                                checked={state.checked === 0 ? true : false}
                                onChange={() => setState(prevState => ({ ...prevState, checked: 0 }))}
                                name="view-type"
                                defaultValue=""
                                defaultChecked="true"
                            />
                            <label htmlFor="type-table" />
                        </div>
                        <div className="radio-container">
                            <input
                                type="radio"
                                id="type-cards"
                                checked={state.checked === 1 ? true : false}
                                onChange={() => setState(prevState => ({ ...prevState, checked: 1 }))}
                                name="view-type"
                                defaultValue=""
                            />
                            <label htmlFor="type-cards" />
                        </div>
                        <p className="hover" style={{ marginTop: 27.5 }}><Link to={"/entire_catalog"}>View Entire Catalog</Link></p>
                    </div>
                </div>
                <div style={{ width: "100%", minHeight: "100vh" }} className="container-fluid">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="row" style={{ width: "100%" }}>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-12" id={width <= 775 ? "" : "fixside"}>
                                <h2 className="grid-title">
                                    <img src={require("../../../assets/icons/filter.png")} style={{ maxWidth: 35, maxHeight: 35, height: 35, width: 35 }} /> Filters
                                </h2>
                                    <div className="col-md-5 col-lg-5 col-xl-5 col-sm-12">
                                        <Downshift
                                            onChange={selection => {
                                                navigate(`/lot_page/${selection.id}`);
                                            }}
                                            itemToString={item => (item ? item.title : '')}
                                        >
                                            {({
                                                getInputProps,
                                                getItemProps,
                                                getLabelProps,
                                                getMenuProps,
                                                isOpen,
                                                inputValue,
                                                highlightedIndex,
                                                selectedItem,
                                                getRootProps
                                            }) => (
                                                <div className="col-12">
                                                    <div id="selectmobiletwo"
                                                        style={{display: 'inline-block'}}
                                                        {...getRootProps({}, {suppressRefError: true})}
                                                    >
                                                        <input className="specialsearchinput form-control" type="text" placeholder="SEARCH CATALOG" {...getInputProps()} />
                                                    </div>
                                                    <ul {...getMenuProps()}>
                                                        {isOpen ? state.allListings.filter(item => !inputValue || item.title.toLowerCase().includes(inputValue.toLowerCase())).map((item, index) => (
                                                            <li
                                                                {...getItemProps({
                                                                    key: item.title,
                                                                    index,
                                                                    item,
                                                                    style: {
                                                                        backgroundColor:
                                                                        highlightedIndex === index ? 'lightgray' : 'white',
                                                                        fontWeight: selectedItem === item ? 'bold' : 'normal',
                                                                    },
                                                                })}
                                                                className="list-styled-item"
                                                            >
                                                                {item.title}
                                                            </li>
                                                        )) : null}
                                                    </ul>
                                                </div>
                                            )}
                                        </Downshift>
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                        <Link to="/entire_catalog" className="forgot-password row" style={{ width: "20vw", marginLeft: 0, marginTop: 15 }}>
                                            View Entire Catalog
                                        </Link>
                                    </div>
                                <hr />
                                <div className="padding" />
                                <input type="hidden" id="dtp_input2" defaultValue="" />
                                {/* END FILTER BY DATE */}
                                <div className="padding" />
                                <div className="row" style={{ width: "20vw", marginLeft: 0, marginTop: 15 }}>
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                    <h4 style={{ paddingTop: 12.5 }}>Category:</h4>
                                    <Select
                                        value={state.selectedCategory}
                                        className="form-control spacing-input"
                                        id="selectmobile"
                                        placeholder={"CATEGORY"}
                                        onChange={(value) => {
                                            setState(prevState => ({ ...prevState, selectedCategory: value }))

                                            handleSelectionChangeCategory(value)
                                        }}
                                        options={optionsCategory}
                                    />
                                        {/* Between ${state.priceValuesOne[0]} to ${state.priceValuesTwo[0]} */}
                                    </div>
                                </div>
                                {/* BEGIN FILTER BY PRICE */}
                                <div className="row" style={width <= 1150 ? { width: "100%", marginLeft: 0, marginTop: 15 } : { width: "20vw", marginLeft: 0, marginTop: 15 }}>
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                    <h4 style={{ paddingTop: 12.5 }}>By price:</h4>
                                        Between ${state.priceValuesOne[0]} to ${state.priceValuesTwo[0]}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <input className="form-control" type="number" onChange={(e) => {
                                                setState(prevState => ({ ...prevState, priceValuesOne: [Number(e.target.value)] }));

                                                searchLowerValues([Number(e.target.value)])
                                            }} placeholder="LOW VALUE" />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <input className="form-control" type="number"  onChange={(e) => {
                                                setState(prevState => ({ ...prevState, priceValuesTwo: [Number(e.target.value)] }));

                                                searchHigherValues([Number(e.target.value)])
                                            }} placeholder="HIGH VALUE" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <Range
                                    step={1}
                                    min={1}
                                    max={100000}
                                    values={state.priceValuesOne}
                                    onChange={(values) => setState(prevState => ({ ...prevState, priceValuesOne: values }))}
                                    onFinalChange={(values) => searchLowerValues(values)}
                                    renderTrack={({ props, children }) => (
                                        <div>
                                            <p className="leadfive">Lowest: </p>
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: '6px',
                                                    width: '100%',
                                                    backgroundColor: '#ccc'
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#999'
                                        }}
                                    />
                                    )}
                                />
                                <hr />
                                <Range
                                    step={1}
                                    min={1}
                                    max={100000}
                                    values={state.priceValuesTwo}
                                    onFinalChange={(values) => searchHigherValues(values)}
                                    onChange={(values) => state.priceValuesTwo[0] >= state.priceValuesOne[0] ? setState(prevState => ({ ...prevState, priceValuesTwo: values })) : null}
                                    renderTrack={({ props, children }) => (
                                        <div>
                                            <p className="leadfour">Highest: </p>
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: '6px',
                                                    width: '100%',
                                                    backgroundColor: '#ccc'
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#999'
                                        }}
                                    />
                                    )}
                                />
                                <hr />
                            </div>
                            <div className="mx-auto col-md-9 col-sm-12 col-lg-9">
                                <div id="fixside" style={{ minHeight: "92.5vh" }}>
                                    {renderMainContent()}
                                    <div className="position-bottom">
                                        <ResponsivePagination
                                            current={state.currentPage}
                                            total={state.totalPages}
                                            onPageChange={(page) => setState(prevState => ({ ...prevState, currentPage: page === 1 ? 0 : page - 1 }))}
                                        />
                                    </div>
                                </div>
                            </div>           
                        </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        authData: state.auth.data
    }
};

export default connect(mapStateToProps, { })(IndividualAuctionViewHelper);