import React, { Fragment } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size'

const AboutUsHelper = (props) => {
    const [width, height] = useWindowSize()
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="small-content" />
            <div className="row">
            <div className="row" style={{ padding: 22.25, paddingTop: 82.25 }}>
                <section className="section gray-bg" id="about">
                    <h1>About Us</h1>
                    <p><b>Welcome to The Chicago Violin Auction</b>. With a focus on the consignment and sale of violin family instruments and their respective bows, we are a pioneering startup-auction, based in one of the violin trades historic epicenters - downtown Chicago. The mission of The Chicago Violin Auction is to start an old-school, barebones style violin auction, modernized as an internet-based provider. <br/><br/>Currently, auction buyers and sellers in the U.S. have limited choices. Non-string-instrument-specific auction houses lack the expertise or credibility to generate high wholesale prices and fail to maintain post-sale anonymity and discretion on the Internet. String-instrument-specific auction houses are forced to reject many potential offerings due to excessive seller-side demand. At the same time, the string-instrument-specific auction houses' efforts to generate retail traffic make them a less desirable marketplace for trade buyers. Chicago Violin Auction LLC will fill the gap between retail and wholesale that has been widening as auction houses have increasingly catered to the retail trade. </p>
                    {/* <div className="container">
                        <div className="row align-items-center flex-row-reverse">
                            <div className="col-lg-d col-md-d col-xl-12 col-md-12 col-sm-12">
                                <div className="about-text go-to">
                                    <h3 style={{ marginTop: 32.25 }} id="verytopmobile" className="dark-color">About Me And My Company</h3>
                                    <h6 className="theme-color lead">
                                        Harmonizing Passions: A Musical Maestro with a Flair for Sales
                                    </h6>
                                    <p style={{ marginLeft: 0 }} id="about">
                                        {" "}
                                        Welcome to [Your Company Name], where the rhythm of commerce meets the melody of opportunity. As passionate advocates of the musical arts, we have harmonized our expertise to create a unique platform dedicated to auctioning high-quality musical equipment. With a melody that resonates through the digital landscape, we bring together sellers and buyers, turning every transaction into a symphony of satisfaction.
                                        <br />
                                        <br />
                                        At [Your Company Name], we understand the heartbeat of musicians, producers, and enthusiasts alike. Whether you're a seasoned professional searching for that vintage amplifier or a budding artist in need of your first instrument, our platform is a vibrant marketplace where dreams find their crescendo. We specialize in hosting auctions that cater to the diverse spectrum of musical tools and instruments, ensuring that our virtual stage is adorned with everything from classical instruments to cutting-edge digital equipment.
                                        <br />
                                        <br />
                                        Why choose [Your Company Name]? We pride ourselves on offering a dynamic, user-friendly experience that strikes the right chord with both buyers and sellers. Our team of experts meticulously curates listings to ensure that only the finest instruments and tools take center stage. Sellers benefit from our robust platform, gaining access to a vast audience of music enthusiasts eager to discover their next prized possession.
                                        <br />
                                        <br />
                                        Here at [Your Company Name], we believe in more than just transactions; we believe in building a community. Our commitment to excellence extends beyond the bidding process, as we provide a space for musicians to connect, share insights, and celebrate their shared love for music. With every auction, we are not just facilitating the exchange of musical equipment; we are orchestrating the creation of new musical journeys.
                                        <br />
                                        <br />
                                        Join us at [Your Company Name] and experience the crescendo of possibilities. Whether you're looking to sell, buy, or simply connect with fellow music aficionados, our platform is your stage. Let the bidding begin, and let the music play!
                                    </p>
                                    <div className="row about-list" style={{ margin: 15 }}>
                                        <div className="col-md-4">
                                            <div className="media">
                                                <label>Years Of Experience</label>
                                                <p>13+ Years</p>
                                            </div>
                                            <div className="media">
                                                <label>Age</label>
                                                <p>27 Yrs</p>
                                            </div>
                                            <div className="media">
                                                <label>Item Locations</label>
                                                <p>[Location Goes Here]</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="media">
                                                <label>Expedited Sales?</label>
                                                <p>Yes! We can expedite shipping!</p>
                                            </div>
                                            <div className="media">
                                                <label>Email</label>
                                                <p>johnemailgoeshere@gmail.com</p>
                                            </div>
                                            <div className="media">
                                                <label>Phone</label>
                                                <p>+1 (111)-111-1111</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="media">
                                                <label>Special Requests?</label>
                                                <p>Yes, We take custom requests..</p>
                                            </div>
                                            <div className="media">
                                                <label>Processing Time</label>
                                                <p>1-5 Days</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
                                {/* <div className="about-avatar">
                                    <img
                                        className="special-img-two"
                                        src={require("../../assets/images/disc.png")}
                                        title=""
                                        alt=""
                                    />
                                </div>
                            </div>
                                <img
                                    className="special-img"
                                    src={require("../../assets/images/selfie.jpg")}
                                    title=""
                                    alt=""
                                />
                        </div>
                        <div className="counter">
                        <div className="row">
                            <div className="col-6 col-lg-3">
                            <div className="count-data text-center">
                                <h6 className="count h2" data-to={500} data-speed={500}>
                                    45
                                </h6>
                                <p className="m-0px font-w-600 text-center">Total Sales</p>
                            </div>
                            </div>
                            <div className="col-6 col-lg-3">
                            <div className="count-data text-center">
                                <h6 className="count h2" data-to={150} data-speed={150}>
                                    150
                                </h6>
                                <p className="m-0px font-w-600 text-center">Active Listings</p>
                            </div>
                            </div>
                            <div className="col-6 col-lg-3">
                            <div className="count-data text-center">
                                <h6 className="count h2" data-to={850} data-speed={850}>
                                 $99050
                                </h6>
                                <p className="m-0px font-w-600 text-center">Total Sold (USD ~ $)</p>
                            </div>
                            </div>
                            <div className="col-6 col-lg-3">
                            <div className="count-data text-center">
                                <h6 className="count h2" data-to={190} data-speed={190}>
                                  1+
                                </h6>
                                <p className="m-0px font-w-600 text-center">Years in business</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                    </section>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default AboutUsHelper;