import React, { Fragment, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Parallax, Background } from "react-parallax";
import image4 from "../../assets/images/instrument.png"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const markdownFour = `**Payment Options:** When you win an auction at Chicago Violin Auction, you will receive an invoice to be settled within seven days of the auction’s close. Invoices will be sent through email, but can also be viewed in the **‘Won/Lost Auctions’** page under **‘Profile & More.’** You can choose from several convenient payment methods including bank transfer, credit card, cashier's check from a bank, money order, or Zelle. Please note that if you pay by check, the item will only be released once the check has cleared. For those opting to use a credit card, a 2.9% service fee will be added to cover transaction costs.

**Item Collection and Delivery:** After your payment is processed, we will send you details on how to collect or receive delivery of your purchase. At Chicago Violin Auction, we aim to make this process as smooth as possible. We offer an in-house pack-and-ship service for the flat rates outlined below. To inquire about combining shipments, please contact us!`

const BuyersHelperComponent = (props) => {
    const [ activeTab, setActiveTab ] = useState("link1");

    const handleTabClick = (tabId, event) => {
        event.preventDefault();
        setActiveTab(tabId);
    }

    return (
        <Fragment>
            <NavigationUniversalHelper />
                <div className="middle-content">
                    <div className="small-card-container">
                        <h2>Buyers</h2>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adiposcing elit</p> */}
                    </div>
                </div>
                {/* Links Menu - add and rename as many as needed */}
                <div id="maxed-spacer">
                    <ul className="links-menu">
                        <li onClick={(event) => handleTabClick('link1', event)}>
                            <a className="hover" style={{ color: "#fff" }}>Buyers</a>
                        </li>
                        <li onClick={(event) => handleTabClick('link2', event)}>
                            <a className="hover" style={{ color: "#fff" }}>Commissions & Fees</a>
                        </li>
                        <li onClick={(event) => handleTabClick('link3', event)}>
                            <a className="hover" style={{ color: "#fff" }}>Bidding</a>
                        </li>
                        <li onClick={(event) => handleTabClick('link4', event)}>
                            <a className="hover" style={{ color: "#fff" }}>Shipping & Payment</a>
                        </li>
                    </ul>
                    <div className="contact-content">
                        <form id="link1" style={{ display: activeTab === 'link1' ? 'block' : 'none' }}>
                            <h2>Buyers</h2>
                            <hr style={{ width: "750px" }} />
                            <p id="about" style={{ display: "block" }}>
                                <b>Welcome to The Chicago Violin Auction</b>, a new instrument auction catered towards the bowed stringed instrument trade. With operations centered around our online platform and downtown Chicago headquarters, our platform strives for a reliable, fair, and transparent auction process for our buyers. To learn more about buying through The Chicago Violin Auction, you can browse the links above or navigate through our FAQ section below.
                                <br />
                                <br />
                                <br />
                                <b>To our Buyers</b>
                                <br />
                                <br />
                                Stringed instrument buyers operate in a highly unique market, and operating as a niche auction, we strive to target our users' specialized needs. In a trade where confidentiality is paramount, our online auction platform's bidding system enables discreet bidding and post-sale anonymity. Though all of our items are available for inspection prior to the auction at our public viewings, descriptions, high-quality images, and condition reports are accessible online for every lot. If you have questions or need assistance, we are available to address any inquiries you may have through our <b>'Contact Us'</b> page or you can <b>'Email Us'</b> at <a href="mailto:info@chicagoviolinauction.com">info@chicagoviolinauction.com</a>.</p>
                        </form>
                        <form id="link2" style={{ display: activeTab === 'link2' ? 'block' : 'none' }}>
                            <h2>Commissions & Fees</h2>
                            <hr style={{ width: "750px" }} />
                            <p id="about" style={{ display: "block" }}><span><strong>Buyer&rsquo;s Premium</strong>:</span><span> In addition to the hammer price (winning bid), buyers are required to pay a buyer's premium, set at 20% of the hammer price. The final purchase price billed to users includes the sum of the hammer price and the buyer's premium. This sum is calculated during the <strong>&lsquo;</strong></span><strong>Place a new bid!</strong><span><strong>&rsquo;</strong> confirmation.&nbsp;</span><br /><br />
                            <span><strong>Taxes</strong>:</span><span> The applicable sales tax rate for each transaction is determined by the location where the buyer takes possession of the item. Per Illinois tax regulations, our auction house collects sales tax on all items either picked up within the state or shipped to an Illinois address. Buyers should note that purchases shipped outside Illinois may incur additional taxes based on the destination; however, our auction house does not remit these taxes.&nbsp;</span>
                            </p>
                        </form>
                        <form id="link3" style={{ display: activeTab === 'link3' ? 'block' : 'none', minWidth: "96vw", margin: 12.25 }}>
                            <h2>Bidding</h2>
                            <hr style={{ width: "750px" }} />
                            <p><strong>Registration Form:</strong> In order to bid, users must complete a bidder registration form found on our registration page. As part of the bidder registration process, a registration fee of $1.00 will be placed on your card. This serves as a measure to verify the validity of your payment method and will serve as your primary payment card on file. Verification and storage of card details are not handled by our servers, but by our vendor, Stripe. Lastly, the user is required to provide a valid photo ID as part of the bidder registration process, such as a drivers license or passport, in order to complete the registration process.</p>
                            <h2>Bidding Process</h2>
                            <p>Once you're registered as a bidder with The Chicago Violin Auction, placing a bid is a straightforward process. Here's a step-by-step guide to help you get started:</p>
                            <ol>
                                <li><strong>1. Browse the Catalog:</strong> Explore our auction catalog to find the instrument or bow that catches your eye. Each item will have descriptions, high-quality photographs, and condition reports to help you make an informed decision. Specific listings can be bookmarked by clicking <strong>‘Add to Favorites.’</strong> Favorited listings can be found under <strong>‘Bookmarked Listing(s)’</strong> in the <strong>‘Profile Settings’</strong> tab. Questions regarding lots can be directed through our <strong>‘Contact Us’</strong> portal or through our email, <a href="mailto:info@chicagoviolinauction.com">info@chicagoviolinauction.com</a>.</li>
                                <br />
                                <li><strong>2. Proxy Bids:</strong> If you have decided on the maximum amount you're willing to bid for the item, enter this amount into the bidding platform. Our system will then automatically bid on your behalf, incrementally increasing your bid as necessary to keep you in the lead up to your maximum bid amount. It is important to note that proxy bids cannot be retracted.</li>
                                <br />
                                <li><strong>3. Monitor the Auction:</strong> Keep an eye on the auction to see how the bidding progresses through live updates and the <strong>‘Bidding History’</strong> menu. You'll also receive email notifications if you've been outbid. Bidding notifications can also be monitored on the <strong>‘Notifications’</strong> page under <strong>‘Profile & More.’</strong></li>
                                <br />
                                <li><strong>4. Extensions:</strong> Bids placed in the last 10 minutes of the auction will reset the countdown timer to 10 minutes. The timer will refresh automatically, though due to how browsers work, users are encouraged to manually refresh the page to check if they are in the winning position.</li>
                                <br />
                                <li><strong>5. Winning the Auction:</strong> If you're the highest bidder when the auction closes, congratulations! You'll receive confirmation of your winning bid and instructions on how to proceed with payment and shipping. Unsure whether you won? Visit the <strong>‘Won/Lost Auctions’</strong> page under <strong>‘Profile & More.’</strong></li>
                                <br />
                                <li><strong>6. Payment and Collection:</strong> Payment options can be found in our shipping and payment section. Once payment is received, you can collect your winning item either in Chicago in person or arrange for shipping.</li>
                            </ol>
                        </form>
                        <form id="link4" style={{ display: activeTab === 'link4' ? 'block' : 'none' }}>
                            <h2>Shipping & Payment</h2>
                            <hr style={{ width: "750px" }} />
                            <Markdown remarkPlugins={[remarkGfm]} className="widemarkdown">{markdownFour}</Markdown>
                            <img src={require("../../assets/images/diagram.png")} style={{ marginLeft: 30 }} />
                        </form>
                    </div>
                </div>
                <div className="middle-content-backgrounded">
                    <div className="container" style={{ backgroundColor: "#fff" }}>
                        <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                            <Accordion>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Can I retract a bid or max bid after it is placed?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        The bids placed on our site, including proxy bids, are legally binding agreements to purchase items at the bid price if they are the last and final bid. Once a bid or proxy bid is placed in our auction, it is considered final. We do not allow bid retractions or adjustments under any circumstances. Thus, it is essential for all participants to carefully consider their bids before placing them.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How can I inspect the violins before bidding?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        We offer two convenient options for inspecting violins: online viewing and in-person inspection at our Chicago location. Our website provides detailed listings with high-resolution photos and comprehensive descriptions, allowing you to assess the instruments from home. If you're in the wonderful city of Chicago, you're welcome to visit us for a hands-on inspection where our staff can assist you. For further inquiries or assistance during the inspection process, feel free to contact us. We're here to ensure your auction experience is informed and enjoyable.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What are the conditions and provenances of the instruments offered?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        The conditions and provenances of the instruments offered vary depending on each listing. We strive to provide comprehensive information for each violin, including details about its condition, provenance, and any relevant historical or ownership background. Our listings may include information about the instrument's age, maker, previous owners, restoration history, and any accompanying documentation or certificates of authenticity.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Are there any guarantees or returns?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        We do not offer guarantees or returns on instruments sold through our auctions. It's essential for bidders to carefully review all available information, including photos, descriptions, and condition reports, before placing a bid. We will make every effort to provide accurate and detailed information about each violin to assist bidders in making informed decisions. Additionally, we encourage bidders to inspect instruments in person or inquire about specific details before bidding. By participating in our auctions, bidders acknowledge and accept the conditions of sale, including the absence of guarantees or returns.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Why is there a charge on my card after I registered as a bidder?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        The auction house employs a $1.00 charge to validate new payment methods. This charge is processed using Stripe, our secure payment processor and typically occurs within 24 hours of registering a new payment method. 
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What is a proxy bid?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        A proxy bid, also known as a max bid, is a feature that enables bidders to set the highest amount they are willing to pay for an item. When placing a proxy bid, bidders enter their maximum bid amount into the auction system. The system then automatically places incremental bids on their behalf, up to their maximum bid, as other bidders place competing bids.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What is a reserve?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        A reserve price is the minimum amount that the seller is willing to accept for an item in an auction. It is a predetermined price agreed upon by the seller and the auction house, not disclosed to bidders, which must be met or exceeded for the item to be sold. If the highest bid at the end of the auction does not meet or exceed the reserve price, the item may not be sold, and the auction may end without a winner.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What are the bidding increments? 
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <img src={require("../../assets/bidincrements.png")} style={{ maxWidth: 325, maxHeight: 500 }} />
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What happens if I win a bid?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        If you win a bid, congratulations! We will be in touch with you shortly. You'll be required to pay the hammer price plus any applicable buyer's premium and taxes. After payment, you can arrange for the pickup or delivery of the violin. It's essential to review the auction house's terms and conditions regarding payment, pickup, and shipping.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
                <Parallax
                    bgImage={image4}
                    strength={475}
                    renderLayer={(percentage) => (
                        <div />
                    )}
                    >
                    <div style={{ height: 175 }}>
                        
                    </div>
                </Parallax>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default BuyersHelperComponent;