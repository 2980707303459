import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { connect } from "react-redux";
import "./styles.css";
import { authentication } from "../../../redux/actions/authentication/auth";
import _ from "lodash";
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size'

const NavigationUniversalHelper = ({ authData, authentication }) => {
    const [width, height] = useWindowSize()
    console.log("authData authData authData authData", authData);

    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(true);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
  
    const handleNavIconClick = () => {
        toggleMenu();
    };

    return (
        <Fragment>
            <header>
                <div style={{ paddingTop: 12.5, paddingBottom: 12.5 }} className="logo-container">
                    <Link to="/">
                        <img
                        src={require("../../../assets/CVA Logo Bold Text.jpg")}
                        alt="CVA logo"
                        className="logo"
                        />
                    </Link>
                </div>
                <div className={menuOpen === true ? "header-right" : "header-right-full"}>
                    {typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true ? <div className="display-none" id="absolutebtn" onClick={() => {
                            authentication({});

                            navigate("/");
                        }}>
                        <p id="redbutton" className="btn" style={{ color: "#fff", marginTop: -10 }}>Logout</p>
                    </div> : <Link style={{ marginTop: 15 }} to="/login" id="absolutebtnlogin"><p style={{ color: "#fff", marginTop: -15 }} className="btn">Login</p></Link>}
                    {typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true ? <a id="absolutebtntwo" onClick={() => navigate("/profile_settings")} className="profile-icon-container hover">
                        <img className="display-none" src={require("../../../assets/icons/profile.png")} style={{ width: 45, height: 45, marginTop: -10 }} />
                    </a> : null}
                    <nav id="nav-bar">
                        <div className="nav-menu">
                            <div className="nav-icon" onClick={handleNavIconClick}>
                                <img
                                src={require("../../../assets/Navigation Menu.png")}
                                alt="Nav icon"
                                />
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            <Sidebar collapsedWidth={0} toggled={menuOpen} collapsed={menuOpen}>
                <Menu
                    menuItemStyles={{
                        button: {
                            [`&.active`]: {
                                backgroundColor: '#13395e',
                                color: '#b6c8d9',
                            },
                        }
                    }}
                >
                    <div style={{ marginBottom: "25px" }} onClick={toggleMenu}>
                        <img style={{ maxWidth: 50, maxHeight: 50 }} src={require("../../../assets/icons/closeicon.png")} />
                    </div>
                    <MenuItem component={<Link to="/" />}> Homepage</MenuItem>
                    <MenuItem component={<Link to="/buyers" />}> Buyers</MenuItem>
                    <MenuItem component={<Link to="/sellers" />}> Sellers</MenuItem>
                    <SubMenu label="About & Contact">
                        {/* <MenuItem component={<Link to="/messaging" />}> Messaging/Chat</MenuItem> */}
                        <MenuItem component={<Link to="/about_us" />}> About Us</MenuItem>
                        <MenuItem component={<Link to="/contact" />}> Contact Us</MenuItem>
                    </SubMenu>
                    <SubMenu label="Profile & More">
                        <MenuItem component={<Link to="/profile_settings" />}> Profile Settings</MenuItem>
                        <MenuItem component={<Link to="/notifications" />}> Notifications</MenuItem>
                        <MenuItem component={<Link to="/ended_auctions" />}> Won/Lost Auctions</MenuItem>
                    </SubMenu>
                    <SubMenu label="TOS">
                        {/* <MenuItem component={<Link to="/messaging" />}> Messaging/Chat</MenuItem> */}
                        <MenuItem component={<Link to="/terms_and_conditions" />}> TOS</MenuItem>
                        <MenuItem component={<Link to="/privacy_policy" />}> Privacy Policy</MenuItem>
                        <MenuItem component={<Link to="/cookie_policy" />}> Cookie Policy</MenuItem>
                    </SubMenu>
                    <MenuItem component={<Link to="/current_auc" />}> Current Auction</MenuItem>
                    {_.has(authData, "admin") && authData.admin === true ? <MenuItem component={<Link to="/admin_panel" />}> Admin Panel</MenuItem> : null}
                    {width <= 775 ? (typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true) ? <Fragment>
                        <MenuItem onClick={() => {
                            authentication({});

                            navigate("/");
                        }}> Sign-Out</MenuItem>
                    </Fragment> : <Fragment>
                        <MenuItem component={<Link to="/login" />}> Sign-Up/In</MenuItem>
                    </Fragment> : null}
                </Menu>
            </Sidebar>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null
    }
};

export default connect(mapStateToProps, { authentication })(NavigationUniversalHelper);