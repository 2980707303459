import React, { Fragment, useEffect, useState } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import "./styles.css";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';
import axios from "axios";
import ContentLoader from 'react-content-loader'
import SlidingPane from "react-sliding-pane";
import {
    useWindowSize
} from '@react-hook/window-size'
import Slider from "react-slick";
import moment from "moment";
import uuid from "react-uuid";
import _ from "lodash";
import { confirmAlert } from 'react-confirm-alert';
                                            
const PERCENTAGE_FEE = 0.20;
const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    autoplaySpeed: 2500,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true
};     

const EndedWonLostAuctionsHelper = ({ authData }) => {
    const [width, height] = useWindowSize()   
    const [ state, setState ] = useState({
        listings: [],
        wonListings: [],
        selected: null,
        lostListings: [],
        isPaneOpenLeft: false,
        user: null,
        paymentMethods: [],
        showPaymentMethods: false,
        bidAmount: null,
        generatedPaymentNum: uuid(),
        userPaymentsIDS: []
    });

    useEffect(() => {
        const configuration = {
            params: {
                uniqueId: authData.uniqueId
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/won/lost/auctions`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings, winning, losing, userPayments } = res.data;

                const processedPaymentsIDs = userPayments.data.map((payment) => payment.metadata.paymentNumber);

                setState(prevState => ({ ...prevState, listings, wonListings: winning, lostListings: losing, userPaymentsIDS: processedPaymentsIDs }));

                // NotificationManager.success('Successfully fetched/gathered ended listings!', 'Successfully gathered listings.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, [state.listing]);

    const handleSelection = (listing) => {
        const maxBid = listing.bidsArray.reduce((max, obj) => (obj.bidAmount > max.bidAmount ? obj : max), listing.bidsArray[0]);
        const bidderID = maxBid.bidderID;

        const configuration = {
            params: {
                uniqueId: bidderID,
                accountType: "standard"
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/gather/general/info/user`, configuration).then((res) => {
            if (res.data.message === "Successfully ran logic!") {
                console.log("res.data", res.data);

                const { user } = res.data;
                
                setState(prevState => ({ ...prevState, selected: listing, isPaneOpenLeft: true, user, bidAmount: maxBid.bidAmount }))
            } else {
                console.log("err");
            }
        })

        console.log("maxBid", maxBid);
    }

    
    const handleSubmitPaymentActuallyPay = (e, paymentMethod) => {
        e.preventDefault();

        console.log("handleSubmitPaymentActuallyPay clicked/ran...");

        const maxBid = state.selected.bidsArray.reduce((max, obj) => (obj.bidAmount > max.bidAmount ? obj : max), state.selected.bidsArray[0]);

        const configuration = {
            maxBid,
            uniqueId: authData.uniqueId,
            selectedListing: state.selected,
            paymentMethod,
            shipppingCost: selected.shippingCost,
            paymentNumber: selected.id
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/handle/purchase/final/pay/checkout`, configuration).then((res) => {
            if (res.data.message === "Successfully ran logic!") {
                console.log("res.data", res.data);

                const { listing } = res.data;

                setState(prevState => ({ ...prevState, isPaneOpenLeft: false, paymentMethods: [], showPaymentMethods: false, listing }));

                NotificationManager.success("Successfully paid for this item, check your email for more details!", "Successfully processed payment!", 3750);
            } else {
                console.log("err");
            }
        })
    };


    const handleSubmitPayment = (e) => {
        e.preventDefault();

        console.log("handleSubmitPayment clicked/ran...");

        const maxBid = state.selected.bidsArray.reduce((max, obj) => (obj.bidAmount > max.bidAmount ? obj : max), state.selected.bidsArray[0]);

        const configuration = {
            maxBid,
            uniqueId: authData.uniqueId,
            selectedListing: state.selected
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/handle/purchase/gather/payment/methods`, configuration).then((res) => {
            if (res.data.message === "Successfully ran logic!") {
                console.log("res.data", res.data);

                const { paymentMethods } = res.data;

                setState(prevState => ({ ...prevState, paymentMethods: paymentMethods.data, showPaymentMethods: true }))
            } else {
                console.log("err");
            }
        })
    };

    const renderPreviousPaymentsIfAvailable = (listing) => {
        if (typeof listing.payments !== "undefined" && listing.payments.length > 0) {
            return (
                <Fragment>
                    <table className="table user-list">
                        <thead>
                            <tr>
                            <th>
                                <span>Amount</span>
                            </th>
                            <th>
                                <span>Date</span>
                            </th>
                            <th className="text-center">
                                <span>Payment ID</span>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {listing.payments.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <tr>
                                            <td>
                                                ${item.paymentAmount}
                                            </td>
                                            <td>{moment(item.date).fromNow(false)}</td>
                                            <td className="text-center">
                                                <span className="label label-default">{item.paymentID}</span>
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </Fragment>
            );
        } else {
            return null;
        }
    }

    const renderConditional = () => {
        if (state.showPaymentMethods === false) {
            return (
                <Fragment>
                    <div className="row">
                        <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12">
                            <div className="receipt-content">
                                <div className="container">
                                    <div className="row">
                                    <div className="col-md-12">
                                        <div className="invoice-wrapper">
                                        <div className="intro">
                                            Hi <strong>{authData.firstName} {authData.lastName}</strong>,
                                            <br />
                                            You have a total due of <strong style={{ textDecorationLine: "underline" }}>${(state.bidAmount + selected.shippingCost).toFixed(2)}</strong> (USD)
                                            for this listing/item - once payment is received - your item will be immediately shipped to your address on file.
                                        </div>
                                        <div className="payment-info">
                                            <div className="row">
                                            <div className="col-sm-6">
                                                <span>Payment No.</span>
                                                <strong>{selected !== null ? selected.id : null}</strong>
                                            </div>
                                            <div className="col-sm-6 text-right">
                                                <span>Payment Due Date</span>
                                                <strong>{moment(selected.endDate).add(5, "days").format("MM/DD/YYYY")}</strong>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="payment-details">
                                            <div className="row">
                                            <div className="col-sm-6">
                                                <span>Client/Purchaser</span>
                                                <strong>{authData.firstName} {authData.lastName}</strong>
                                                <p>
                                                {typeof user.selectedAddress !== "undefined" && _.has(user.selectedAddress, "formatted_address") ? user.selectedAddress.formatted_address : "Address (user.selectedAddress) not available on this account."} <br />
                                                USA <br />
                                                <a href="#">{user.email}</a>
                                                </p>
                                            </div>
                                            <div className="col-sm-6 text-right">
                                                <span>Payment To</span>
                                                <strong>John Ryan</strong>
                                                <p>
                                                [Your address here] <br />
                                                [Your address here] <br />
                                                [Your address here] <br />
                                                USA <br />
                                                <a href="mailto:johnryan@gmail.com">johnryan@gmail.com</a>
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="line-items">
                                            <div className="headers clearfix">
                                            <div className="row">
                                                <div className="col-xs-4 col-4 col-xs-12">Description</div>
                                                <div className="col-xs-3 col-3 col-xs-12">Quantity</div>
                                                <div className="col-xs-5 col-5 col-xs-12 text-right">Bid Amount</div>
                                            </div>
                                            </div>
                                            <div className="items">
                                            <div className="row item">
                                                <div className="col-xs-4 col-4 col-xs-12 desc">Purchase of musical item/instrument</div>
                                                <div className="col-xs-3 col-3 col-xs-12 qty">1</div>
                                                <div className="col-xs-5 col-5 col-xs-12 amount text-right">${(state.bidAmount).toFixed(2)}</div>
                                            </div>
                                            </div>
                                            <div style={{ paddingBottom: 100 }} className="total text-right">
                                                <p className="extra-notes">
                                                    <strong>Extra Notes</strong>
                                                    We ship all items within 2-3 business days, once your delivery is out and shipped, you will receive an update/msg with tracking details and information!
                                                </p>
                                                <div style={{ float: "right" }}>
                                                    <div className="field">
                                                        Subtotal <span>${(state.bidAmount).toFixed(2)}</span>
                                                    </div>
                                                    <div className="field">
                                                        Shipping <span>${(selected.shippingCost).toFixed(2)}</span>
                                                    </div>
                                                    <div className="field">
                                                        Commission <span>${(state.bidAmount * PERCENTAGE_FEE).toFixed(2)} (15%)</span>
                                                    </div>
                                                    {/* <div className="field">
                                                        Discount <span>4.5%</span>
                                                    </div> */}
                                                    <div className="field grand-total">
                                                        Total <span>${(state.bidAmount + selected.shippingCost + (state.bidAmount * PERCENTAGE_FEE)).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            {/* <div className="print">
                                            <a href="#">
                                                <i className="fa fa-print" />
                                                Download this invoice
                                            </a>
                                            </div> */}
                                        </div>
                                        </div>
                                        {renderPreviousPaymentsIfAvailable(state.selected)}
                                        <div style={{ marginTop: 20 }} className="centered">
                                            <button style={{ width: "78%" }} onClick={handleSubmitPayment} className="btn btn-primary">
                                                Select Card For Payment
                                            </button>
                                        </div>
                                        <div className="footer">Copyright © 2024. Chicago Violin Auction</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                            <div className="profile-wrapper">
                                <div className="profile-section-user">
                                    <Slider className="profile-cover-img" {...settings}>
                                        {typeof selected.images !== "undefined" && selected.images.length > 0 ? selected.images.map((item, index) => {
                                            return (
                                                <div className="profile-cover-img">
                                                    <img
                                                        src={item.location}
                                                        alt=""
                                                        className="cover"
                                                    />
                                                </div>
                                            );
                                        }) : null}
                                    </Slider>
                                    <div className="profile-info-brief p-3">
                                    {state.user !== null ? <img
                                        className="img-fluid user-profile-avatar"
                                        src={_.has(state.user, "profilePic") ? `${process.env.REACT_APP_BASE_ASSET_URL}/${state.user.profilePic.link}` : `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`}
                                        alt=""
                                    /> : <img
                                        className="img-fluid user-profile-avatar"
                                        src="https://bootdey.com/img/Content/avatar/avatar6.png"
                                        alt=""
                                    />}
                                    <div className="text-center">
                                        <h5 className="text-uppercase mb-4">{selected.title}</h5>
                                        <p className="text-muted fz-base">
                                            <b style={{ textDecorationLine: "underline" }}>Desc</b>: {selected.itemDescription}
                                        </p>
                                    </div>
                                    </div>
                                    {/* /.profile-info-brief */}
                                    <hr className="m-0" />
                                    <div className="hidden-sm-down">
                                    <hr className="m-0" />
                                    <div className="profile-info-contact p-4">
                                        <h6 className="mb-3">Listing Information</h6>
                                        <table>
                                            <tbody>
                                                {/* <tr>
                                                <td>
                                                    <strong>Total Listing Views:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{typeof selected.listingViews !== "undefined" && selected.listingViews.length > 0 ? selected.listingViews.length : 0} (total views)</p>
                                                </td>
                                                </tr> */}
                                                {/* <tr>
                                                <td>
                                                    <strong>Contact Information:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{selected.contactInformation}</p>
                                                </td>
                                                </tr> */}
                                                <tr>
                                                <td>
                                                    <strong>Total Bids:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{selected.bidsArray.length} bids placed</p>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <strong>Start Date:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{moment(selected.startDate).format("MM/DD/YYYY hh:mm a")}</p>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <strong>End Date:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{moment(selected.endDate).format("MM/DD/YYYY hh:mm a")}</p>
                                                </td>
                                                </tr>
                                                {/* <tr>
                                                <td>
                                                    <strong>Bid Increment:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">${selected.bidIncrementValue.toFixed(2)}</p>
                                                </td>
                                                </tr> */}
                                                <tr>
                                                <td>
                                                    <strong>Shipping Config:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">Shipped Via {selected.shippingOption.label}</p>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <strong>Category:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{selected.lotType}</p>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div className="row">
                        <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12">
                            <div className="receipt-content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="invoice-wrapper row">
                                                {typeof state.paymentMethods !== "undefined" && state.paymentMethods.length > 0 ? state.paymentMethods.map((payment, index) => {
                                                    console.log("payment", payment);
                                                    return (
                                                        <Fragment key={index}>
                                                            <div className="col-md-6">
                                                                <div className="payment-card">
                                                                    <i className="fa fa-cc-visa payment-icon-big text-success" />
                                                                    <h4>**** **** **** {payment.card.last4}</h4>
                                                                    <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <small>
                                                                        <strong>Expiry date:</strong> {payment.card.exp_month}/{payment.card.exp_year}
                                                                        </small>
                                                                    </div>
                                                                    <div className="col-sm-6 text-right">
                                                                        <small>
                                                                        <strong>Type:</strong> {payment.type === "card" ? "Card (Debit/Credit)" : "Unknown"}
                                                                        </small>
                                                                    </div>
                                                                    </div>
                                                                    <button style={{ width: "100%", marginTop: 20 }} onClick={(e) => {
                                                                        confirmAlert({
                                                                            title: `Are you sure you want to make this payment?`,
                                                                            message: `All payments are subject to 2.9% fee by our vendor, Stripe. We cannot/do-not control this, Please continue with your payment.`,
                                                                            buttons: [
                                                                                {
                                                                                    label: 'Yes, Pay & Accept!',
                                                                                    onClick: () => {
                                                                                        console.log("yes pay");
                                                                
                                                                                        handleSubmitPaymentActuallyPay(e, payment);
                                                                                    }
                                                                                },
                                                                                {
                                                                                    label: `No, Don't Pay.`,
                                                                                    onClick: () => {
                                                                                        console.log("no dont pay");
                                                                                    }
                                                                                }
                                                                            ]
                                                                        });
                                                                    }} className="btn btn-primary">
                                                                        Select Card/Pay!
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    );
                                                }) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                            <div className="profile-wrapper">
                                <div className="profile-section-user">
                                    <Slider className="profile-cover-img" {...settings}>
                                        {typeof selected.images !== "undefined" && selected.images.length > 0 ? selected.images.map((item, index) => {
                                            return (
                                                <div className="profile-cover-img">
                                                    <img
                                                        src={item.location}
                                                        alt=""
                                                        className="cover"
                                                    />
                                                </div>
                                            );
                                        }) : null}
                                    </Slider>
                                    <div className="profile-info-brief p-3">
                                    {state.user !== null ? <img
                                        className="img-fluid user-profile-avatar"
                                        src={_.has(state.user, "profilePic") ? `${process.env.REACT_APP_BASE_ASSET_URL}/${state.user.profilePic.link}` : `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`}
                                        alt=""
                                    /> : <img
                                        className="img-fluid user-profile-avatar"
                                        src="https://bootdey.com/img/Content/avatar/avatar6.png"
                                        alt=""
                                    />}
                                    <div className="text-center">
                                        <h5 className="text-uppercase mb-4">{selected.title}</h5>
                                        <p className="text-muted fz-base">
                                            <b style={{ textDecorationLine: "underline" }}>Desc</b>: {selected.itemDescription}
                                        </p>
                                    </div>
                                    </div>
                                    {/* /.profile-info-brief */}
                                    <hr className="m-0" />
                                    <div className="hidden-sm-down">
                                    <hr className="m-0" />
                                    <div className="profile-info-contact p-4">
                                        <h6 className="mb-3">Listing Information</h6>
                                        <table>
                                            <tbody>
                                                {/* <tr>
                                                <td>
                                                    <strong>Total Listing Views:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{typeof selected.listingViews !== "undefined" && selected.listingViews.length > 0 ? selected.listingViews.length : 0} (total views)</p>
                                                </td>
                                                </tr> */}
                                                {/* <tr>
                                                <td>
                                                    <strong>Contact Information:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{selected.contactInformation}</p>
                                                </td>
                                                </tr> */}
                                                <tr>
                                                <td>
                                                    <strong>Total Bids:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{selected.bidsArray.length} bids placed</p>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <strong>Start Date:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{moment(selected.startDate).format("MM/DD/YYYY hh:mm a")}</p>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <strong>End Date:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{moment(selected.endDate).format("MM/DD/YYYY hh:mm a")}</p>
                                                </td>
                                                </tr>
                                                {/* <tr>
                                                <td>
                                                    <strong>Bid Increment:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">${selected.bidIncrementValue.toFixed(2)}</p>
                                                </td>
                                                </tr> */}
                                                <tr>
                                                <td>
                                                    <strong>Shipping Config:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">Shipped Via {selected.shippingOption.label}</p>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <strong>Category:</strong>
                                                </td>
                                                <td>
                                                    <p className="text-muted mb-0">{selected.lotType}</p>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }

    const { selected, user } = state;

    const calculateSecondary = (listing) => {
        if (typeof listing.payments !== "undefined" && listing.payments.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    console.log("stateeeeeeeeeeeeeeeeeeee :", state);
    console.log("lostListings", state.lostListings);
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <SlidingPane
                closeIcon={<img src={require("../../../assets/icons/closeicon.png")} style={{ width: 40, height: 40, maxHeight: 40, maxWidth: 40 }} />}
                isOpen={state.isPaneOpenLeft}
                title="Make a payment, Message our staff & much more..."
                from="right"
                width={width <= 750 ? "100vw" : "80vw"}
                onRequestClose={() => setState(prevState => ({ ...prevState, 
                    selected: null,
                    lostListings: [],
                    isPaneOpenLeft: false,
                    user: null,
                    paymentMethods: [],
                    showPaymentMethods: false,
                    bidAmount: null 
                }))}
            >
                {state.selected !== null && state.user !== null ? renderConditional() : null}
            </SlidingPane>
            <div className="min-height minimumhhh">
                <div className="row">
                    <div style={{ padding: 22.25, backgroundColor: "#fff", borderRight: "1px solid black" }} className="col-md-6 col-lg-6 col-sm-12">
                        <div className="col-md-12 col-md-pull-3">
                            <p className="search-results-count-red">Listings that you have lost...</p>
                            {typeof state.lostListings !== "undefined" && state.lostListings.length > 0 ? state.lostListings.sort((a, b) => new Date(b.endDate) - new Date(a.endDate)).map((listing, index) => {
                                return (
                                    <Fragment key={index}>
                                        <section className="search-result-item" id="cardborder">
                                            <div className="image-link">
                                                <img
                                                    className="image resizeimage"
                                                    src={listing.images[0].location}
                                                />
                                            </div>
                                            <div className="search-result-item-body">
                                                <div className="row">
                                                    <div className="col-sm-9">
                                                    <a className="search-result-item-heading hover">
                                                        <p style={{ fontSize: "1.325rem" }}><em style={{ textDecorationLine: "underline" }}>Title</em>: {listing.title}</p>
                                                    </a>
                                                    {/* <p className="info">New York, NY 20188</p> */}
                                                    <p className="description">
                                                        Lot Type: {listing.lotType}
                                                    </p>
                                                    </div>
                                                    <div className="col-sm-3 text-align-center">
                                                    <p className="value3 mt-sm">${listing.loser.bidAmount}</p>
                                                    <p className="fs-mini" style={{ color: "red", fontSize: "1.5rem", textDecorationLine: "underline" }}>Lost</p>
                                                    {/* <a className="btn-primary btn-info btn-sm" href="#">
                                                        View listing / View More
                                                    </a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </Fragment>
                                );
                            }) : <Fragment>
                                <div style={{ margin: 32.25 }}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <ContentLoader />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>}
                        </div>
                    </div>
                    <div style={{ padding: 22.25, minHeight: "100vh" }} className="col-md-6 col-lg-6 col-sm-12">
                        <div className="col-md-12 col-md-pull-3">
                            <p className="search-results-count">Listings that you have won!</p>
                            {typeof state.wonListings !== "undefined" && state.wonListings.length > 0 ? state.wonListings.sort((a, b) => new Date(b.endDate) - new Date(a.endDate)).map((listing, index) => {
                                return (
                                    <Fragment key={index}>
                                        <section className="search-result-item" id="cardborder">
                                            <div className="image-link">
                                                <img
                                                    className="image resizeimage"
                                                    src={listing.images[0].location}
                                                />
                                            </div>
                                            <div className="search-result-item-body">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                    <a onClick={() => handleSelection(listing)} className="search-result-item-heading hover">
                                                        <p style={{ fontSize: "1.325rem" }}><em style={{ textDecorationLine: "underline" }}>Title</em>: {listing.title}</p>
                                                    </a>
                                                    {/* <p className="info">New York, NY 20188</p> */}
                                                    <p className="description">
                                                        Lot Type: {listing.lotType}
                                                    </p>
                                                    </div>
                                                    <div className="col-sm-4 text-align-center">
                                                    <p className="value3 mt-sm">${listing.winner.bidAmount.toFixed(2)}</p>
                                                    <p className="fs-mini" style={calculateSecondary(listing) ? { color: "green", fontSize: "1.5rem", textDecorationLine: "underline" } : { color: "red", fontSize: "1.5rem", textDecorationLine: "underline" }}>{calculateSecondary(listing) ? "PAID" : "Unpaid"}</p>
                                                    {/* <a className="btn-primary btn-info btn-sm" href="#">
                                                        View listing / View More
                                                    </a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </Fragment>
                                );
                            }) : <Fragment>
                            <div style={{ margin: 32.25 }}>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                        <ContentLoader />
                                    </div>
                                </div>
                            </div>
                        </Fragment>}
                        </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth.data
    }
};

export default connect(mapStateToProps, { })(EndedWonLostAuctionsHelper);