import React, { Fragment, useState, useEffect } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from 'react-notifications';
import { MultiSelect } from "react-multi-select-component";
import ReCAPTCHA from "react-google-recaptcha";
import Slider from "react-slick";
import LoadingOverlay from 'react-loading-overlay';
import "./styles.css";
import {
    useWindowSize
} from '@react-hook/window-size';
import { Parallax } from "react-parallax";
import image4 from "../../assets/images/homepage.jpeg"
import image5 from "../../assets/images/homepage2.jpg"

const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    borderRadius: 35,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
};

const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    autoplaySpeed: 2500,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // centerMode: true,
    draggable: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};    

const optionsssss = [
    { label: "Subscribe to ALL events/news", value: "all-events-everything", disabled: false },
    { label: "Instrument Auction Updates 🎻🎸🎹", value: "instrument-auction-updates", disabled: false },
    // { label: "Exclusive Sales and Discounts 🛍️", value: "exclusive-sales-discounts", disabled: false },
    // { label: "Website Feature Announcements 💡", value: "website-feature-announcements", disabled: false },
    { label: "Music Industry News 📰", value: "music-industry-news", disabled: false },
    { label: "Artist Collaborations and Special Editions 🎨", value: "artist-collaborations-special-editions", disabled: false },
    { label: "Auction Event Reminders ⏰", value: "auction-event-reminders", disabled: false },
    // { label: "New Collection Launches 🚀", value: "new-collection-launches", disabled: false },
    // { label: "Music Technology Innovations 🎛️", value: "music-tech-innovations", disabled: false },
    { label: "Industry Trends and Insights 📊", value: "industry-trends-insights", disabled: false },
    // { label: "Virtual Networking Events 🌐", value: "virtual-networking-events", disabled: false },
    { label: "Educational Workshops and Webinars 📚", value: "educational-workshops-webinars", disabled: false }
];

const validateEmail = (email) => {
    return String(email).toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const LandingPageHelper = (props) => {
    const navigate = useNavigate();
    const [width] = useWindowSize();

    const [ state, setState ] = useState({
        firstName: "",
        lastName: "",
        email: "",
        isLoading: false,
        preferences: [],
        captchaCompleted: false,
        listings: []
    });

    useEffect(() => {
        const configuration = {}

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
                const desiredLength = 5;

                if (listings.length < 5) {
                    while (listings.length < desiredLength) {
                        listings.push(null);
                    }   
                }               

                setState(prevState => ({ ...prevState, listings }));

                
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, [])

    const handleJoinNewsletter = () => {
        console.log("handleJoinNewsletter clicked/ran...");

        const { firstName, lastName, email, preferences, captchaCompleted } = state;

        if (captchaCompleted !== false) {
            if ((typeof firstName !== "undefined" && firstName.length > 0) && (typeof lastName !== "undefined" && lastName.length > 0) && (typeof email !== "undefined" && email.length > 0) && (typeof preferences !== "undefined" && preferences.length > 0) && (validateEmail(email))) {

                setState(prevState => ({ ...prevState, isLoading: true }));

                const configuration = {
                    email,
                    name: `${firstName} ${lastName}`,
                    firstName,
                    lastName,
                    preferences
                };
        
                axios.post(`${process.env.REACT_APP_BASE_URL}/join/newsletter/subscribe`, configuration).then((res) => {
                    if (res.data.message === "Successfully submitted information!") {
                        NotificationManager.success(`We've successfully added you to our newsletter list!`, 'Successfully add to newsletter.', 3000);
        
                        setState(prevState => ({ ...prevState, email: "", firstName: "", lastName: "", preferences: [], captchaCompleted: false, isLoading: false }));
                    } else if (res.data.message === "This email has ALREADY been registered.") {
                        setState(prevState => ({ ...prevState, email: "", firstName: "", lastName: "", preferences: [], captchaCompleted: false, isLoading: false }));
        
                        NotificationManager.warning(res.data.message, 'Cannot re-register this email!', 3000);
                    } else {
                        setState(prevState => ({ ...prevState, isLoading: false }));

                        console.log("err");
                        
                        NotificationManager.error('An unknown error has occurred processing your request.', 'Error occurred with request!', 3000);
                    }
                })
            } else {
                setState(prevState => ({ ...prevState, isLoading: false }));

                NotificationManager.warning("Fill out the REQUIRED VALUES before proceeding...", 'Cannot process request quite yet!', 3000);
            }
        } else {
            setState(prevState => ({ ...prevState, isLoading: false }));

            NotificationManager.info("Please complete the CAPTCHA before submitting the form.", 'Complete CAPTCHA first!', 3000);
        }
    };

    console.log("state.", state);

    const onSelect = (selectedList) => {
        if (typeof selectedList !== "undefined" && selectedList.length > 0) {
            setState(prevState => ({ ...prevState, preferences: selectedList }))
        } 
    }; 

    const customValueRenderer = (selected, _options) => {
        return selected.length
          ? selected.map(({ label }) => "✔️ " + label) 
          : "😶 No Items Selected"; 
    };
 
    return (
        <Fragment>
            <NavigationUniversalHelper />
            {state.isLoading === true ? <LoadingOverlay
                active={state.isLoading}
                spinner
                className={"customspinner"}
                text='Processing your request, please wait...'
            /> : null}
            <div className="content col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12">
            {/*Current Auction Card chamge the text of h2 and p accordingly*/}
                <div className="card-container">
                    {/* <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed out once, initially
                            'Bid on live auctions!',
                            1000, // wait 1s before replacing "Mice" with "Hamsters"
                            'Purchase used/new musical instruments.',
                            1000
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{ display: 'inline-block', fontSize: "27.25px", fontWeight: "bold" }}
                        repeat={Infinity}
                    /> */}
                    <h3>Current Auctions</h3>
                    <p className="listinglist">View our LIVE current auction listings such as violins, violas, cellos, bows, and much more!</p>
                    <Link to="current_auc">
                        <div className="btn" style={{ color: "#fff" }}>View Sale(s)</div>
                    </Link>
                </div>
                {/*Schedule Card - change the text of h2 and p accordingly */}
                <Slider className="schedule-card schedule-card-custom profile-page" {...settings}>
                    <div id="specialpane" onClick={() => {}} className="hover">
                        <div>
                            <h2 className="text-center" style={{ minWidth: "250px", color: "#800020" }}>Consignment Deadline</h2>
                            <p className="text-center" style={{ minWidth: "250px" }}>September 13</p>
                        </div>
                    </div>
                    <div id="specialpane" onClick={() => {}} className="hover">
                        <div>
                            <h2 className="text-center" style={{ minWidth: "250px", color: "#800020" }}>Catalog Online</h2>
                            <p className="text-center" style={{ minWidth: "250px" }}>October 13</p>
                        </div>
                    </div>
                    <div id="specialpane" onClick={() => {}} className="hover">
                        <div>
                            <h2 className="text-center" style={{ minWidth: "250px", color: "#800020" }}>Sale</h2>
                            <p className="text-center" style={{ minWidth: "250px" }}>October 4</p>
                        </div>
                    </div>
                    {/* {typeof state.listings !== "undefined" && state.listings !== null ? state.listings.map((listing, index) => {
                        if (listing === null) {
                            return (
                                <div id="specialpane" onClick={() => {
                                    NotificationManager.warning("This is an empty listing, no redirect taken.", "Empty listing, no redirect!", 3250)
                                }} key={index} className="hover">
                                    <div>
                                        <h2 className="text-center" style={{ minWidth: "250px" }}>Placeholder item/listing.</h2>
                                        <p className="text-center" style={{ minWidth: "250px" }}>Listing is empty, no data is provided...</p>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div id="specialpane" onClick={() => navigate(`/lot_page/${listing.id}`)} key={index} className="hover">
                                    <div>
                                        <h2 className="text-center" style={{ minWidth: "250px", color: "#800020" }}>{listing.lotType.slice(0, 15)}{typeof listing.lotType !== "undefined" && listing.lotType.length >= 15 ? "..." : ""}</h2>
                                        <p className="text-center" style={{ minWidth: "250px" }}>{listing.title.slice(0, 56)}{typeof listing.title !== "undefined" && listing.title.length >= 56 ? "..." : ""}</p>
                                    </div>
                                </div>
                            );
                        }
                    }) : null} */}

                </Slider>        
            </div>
            <div style={{ paddingTop: 40, paddingBottom: 40 }} className="container">

            </div>
            <div className="container">
                <div className="col-lg-12 mb-4 mb-sm-5">
                    <div>
                        <h3 style={{ color: "#800020", marginLeft: 10, textAlign: "center" }}>About us</h3>
                        {/* <span className="section-title text-primary mb-3 mb-sm-4">About Me</span> */}
                        <p><b>Welcome to The Chicago Violin Auction!</b> With a focus on the consignment and sale of violin family instruments and their respective bows, we are a pioneering startup-auction, based in one of the violin trades historic epicenters - downtown Chicago. The mission of The Chicago Violin Auction is to start an old-school, barebones style violin auction, modernized as an internet-based provider. <br/> </p>
                    </div>
                </div>
            </div>
            <Parallax
                bgImage={image4}
                strength={425}
                renderLayer={(percentage) => (
                    <div />
                )}
                >
                <div style={{ height: 300 }}>
                    <div style={insideStyles}>
                        <Link to="/contact" className="btn btn-primary">Consign now</Link>
                    </div>
                </div>
            </Parallax>
            <div className="container bootstrap snippets bootdey">
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="widget widget-gallery">
                    <div style={{ marginTop: 35 }} className="widget-gallery-header">
                        <h3 style={{ color: "#800020", textAlign: "center" }}>Check out some of our listings:</h3>
                        <p style={{  fontWeight: "bold" }} className="text-muted m-t-3">Check out some of our available listings below or navigate to the "Current Auction" page to view them all!</p>
                    </div>
                    <ul className="widget-gallery-list clearfix">
                        {typeof state.listings !== "undefined" && state.listings !== null ? state.listings.slice(0, 20).map((listing, index) => {
                            if (listing !== null) {
                                return (
                                    <li key={index}>
                                        <a onClick={() => {
                                            navigate(`/lot_page/${listing.id}`);
                                        }}>
                                            <img src={typeof listing.images !== "undefined" && listing.images.length > 0 ? listing.images[0].location : "https://auctionupworkclient.s3.amazonaws.com/placeholder.svg"} alt="" />
                                        </a>
                                    </li>
                                );
                            }
                        }) : null}
                    </ul>
                    </div>
                </div>
            </div>
            <div className="news-content-redone col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <h2>Get in The Loop! - Join Our Newsletter</h2>
                <form id="newsletter-form">
                <hr />
                <label className="label">Select which updates you'd like to enroll in:</label>
                <hr />
                <MultiSelect
                    options={optionsssss}
                    value={state.preferences}
                    shouldToggleOnHover={true}
                    onChange={onSelect}
                    valueRenderer={customValueRenderer}
                    labelledBy="Select what updates you'd like:"
                />
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={state.email}
                    onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))}
                    placeholder="YOUR EMAIL"
                    required=""
                    style={{ width: "100%", margin: "12.5px 0px" }}
                />
                <div className="row">
                    <div className="col-sm-12 col-lg-6 col-md-6">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={state.firstName}
                            onChange={(e) => setState(prevState => ({ ...prevState, firstName: e.target.value }))}
                            placeholder="FIRST NAME"
                            required=""
                            style={{ width: "100%", marginBottom: "12.5px" }}
                        />
                    </div>
                    <div className="col-sm-12 col-lg-6 col-md-6">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={state.lastName}
                            onChange={(e) => setState(prevState => ({ ...prevState, lastName: e.target.value }))}
                            placeholder="LAST NAME"
                            required=""
                            style={{ width: "100%", marginBottom: "12.5px" }}
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="centered">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_KEY}
                                onChange={(value) => value ? setState(prevState => ({ ...prevState, captchaCompleted: true })) : null}
                            />
                        </div>
                    </div>
                </div>
                <div onClick={handleJoinNewsletter}>
                    <p className="btn" style={{ color: "#fff", minWidth: "100%" }}>Join</p>
                </div>
                </form>
            </div>
            <Parallax
                bgImage={image5}
                strength={425}
                renderLayer={(percentage) => (
                    <div/>
                )}
                >
                <div style={{ height: 300 }}>
                    <div style={insideStyles}>
                        <Link to="/about_us" className="btn btn-primary">Learn More...</Link>
                    </div>
                </div>
            </Parallax>
            {/*Footer*/}
            <FooterUniversalHelper />
            <div className="copyright">Copyright @ 2024</div>
            <div className="overlay" />
        </Fragment>
    );
} 

export default LandingPageHelper;